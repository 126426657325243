"use client";

import React, { useEffect } from "react";

const GoogleAds = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-859051518";
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", "AW-859051518", {
      " allow_enhanced_conversions": true,
    });
  }, []);

  return null;
};

export default GoogleAds;
