"use client";

import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { Col, Drawer, Image, Row, Space, Menu, Input, Typography } from "antd";
import StaticImage from "../../../../../assets/images/staticImages/SanaUllah.png";
import { debounce } from "lodash";

import "./SideNavDrawer.scss";
import {
  MdClose,
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

const { Search } = Input;
const { Text, Title } = Typography;

export default function SideNavDrawer({
  data,
  userAgent,
  showSideNav,
  setShowSideNav,
}) {
  const router = useRouter();
  const { isMobile, isTablet, isDesktop } = userAgent;

  const [items, setItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [openMenu, setOpenMenu] = useState({ index: null, open: false });
  const [openedItems, setOpenedItems] = useState([]);
  const [selectedItemKey, setSelectedItemKey] = useState(null);
  const [sourceArray] = useState(
    data?.find((item) => item?.label === "Brands")?.children
  );
  const [targetArray, setTargetArray] = useState([]);
  let [brandDependancy, setBrandDependancy] = useState(0);

  function getItem(label, key, icon, children, type, link) {
    return {
      key,
      icon,
      children,
      label,
      type,
      link,
    };
  }

  // submenu keys of first level
  const rootSubmenuKeys = data?.map((item, index) => `cat${index}`);

  const displayMenuItems = (itemIndex) => {
    const currentOpenedItem = openedItems.find(
      (item) => item.itemIndex === itemIndex
    );

    if (currentOpenedItem) {
      setOpenedItems((prevItems) =>
        prevItems.filter((item) => item.itemIndex !== itemIndex)
      );
    } else {
      const childItems = [];

      data[itemIndex]?.children?.forEach((item, index) => {
        childItems.push(
          getItem(
            item.label ? item.label : item.name,
            `sub${index}`,
            null,
            item?.children?.map((subItem, subIndex) =>
              getItem(
                subItem.label ? subItem.label : subItem.name,
                `sub_sub${subIndex}`,
                null,
                subItem?.children,
                null,
                subItem?.link ? subItem?.link : subItem?.handle
              )
            ),
            null,
            item?.link ? item?.link : item?.handle
          )
        );
      });

      setOpenedItems((prevItems) => [...prevItems, { itemIndex, childItems }]);
      const handleBrandDependancy = brandDependancy + 1;
      setBrandDependancy(handleBrandDependancy);
    }
  };

  const onCloseSideNav = () => {
    setShowSideNav(false);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  console.log(openKeys);
  const onSearchBrands = (value) => {
    const brandsData = data?.find((item) => item?.label === "Brands")?.children;
    const results = brandsData?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    const brandMenuItems =
      results?.map((item, index) => ({
        key: `sub${index}`,
        label: item.name,
        link: item.handle,
      })) || [];

    setItems(brandMenuItems);
    setSelectedItemKey(null);
  };

  // Then define debouncedSearchBrands
  const debouncedSearchBrands = debounce(onSearchBrands, 300);

  useEffect(() => {
    const pushWithDelay = () => {
      if (brandDependancy > 0) {
        const firstTwenty = sourceArray.slice(0, 20); // Extract the first 20 elements

        // Push the first 20 elements immediately
        setTargetArray((prevTargetArray) => [
          ...prevTargetArray,
          ...firstTwenty,
        ]);
        // Delay the pushing of the remaining elements after 500ms
        setTimeout(() => {
          const remaining = sourceArray.slice(20); // Extract the remaining elements
          // Push the remaining elements after the delay
          setTargetArray((prevArray) => [...prevArray, ...remaining]);
        }, 500);
      }
    };

    pushWithDelay();
  }, [brandDependancy, sourceArray]);

  return (
    <Drawer
      placement="left"
      open={showSideNav}
      onClose={onCloseSideNav}
      title={(isMobile || isTablet) && "Menu"}
      width={!isMobile || isTablet ? 360 : "100%"}
      rootClassName={
        !isMobile || isTablet
          ? "side-nav-drawer"
          : "side-nav-drawer mobile-side-nav-drawer"
      }
      closeIcon={
        isMobile || isTablet ? <HiOutlineArrowNarrowLeft /> : <MdClose />
      }
    >
      {data?.map((item, itemIndex) => (
        <Row
          key={itemIndex}
          align="middle"
          justify="space-between"
          style={{
            background: item?.background_color,
          }}
          className={!isDesktop && "mobile-side-menu-cat-container"}
        >
          <Col
            // span={18}
            flex="0 0 auto"
            className="flex cursorPointer paddingRight10 paddingLeft10 side-menu-container"
            onClick={() => displayMenuItems(itemIndex)}
          >
            <Space className="side-menu">
              <Col className="arrows flexCenter">
                {openedItems.some((item) => item.itemIndex === itemIndex) ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </Col>

              <Col>
                <Title level={3} style={{ color: item?.text_color }}>
                  {item.label}
                </Title>

                <Col className="sub-cats-desc">
                  {item?.children?.slice(0, 3).map((item, index) => (
                    <Text className="description" key={index}>
                      {item.label ? item.label : item.name}
                      <Text>, </Text>
                    </Text>
                  ))}
                </Col>
              </Col>
            </Space>
          </Col>

          <Col
            // span={6}
            flex="0 0 auto"
          >
            <Image
              style={{ objectFit: "cover" }}
              width="80px"
              height="auto"
              alt="nav image"
              preview={false}
              src={
                item?.image?.cdn_link ? item?.image?.cdn_link : StaticImage?.src
              }
            />
          </Col>
          <Col
            className="brands-container"
            span={
              openedItems.some((item) => item.itemIndex === itemIndex) ? 24 : 0
            }
          >
            {openedItems.some((item) => item.itemIndex === itemIndex) && (
              <>
                {item?.label?.toLowerCase() === "brands" && (
                  <Col className="paddingTop10 paddingRight10 paddingLeft10 side-nav-brands-search">
                    <Search
                      placeholder="Search..."
                      onSearch={onSearchBrands}
                      style={{ border: `1px solid ${item?.background_color}` }}
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          debouncedSearchBrands(e.target.value); // Use debounced version here
                        } else {
                          setItems([]); // Clear the filtered items when input is empty
                        }
                      }}
                      bordered={false}
                      allowClear
                    />
                  </Col>
                )}

                <Menu
                  className="brands-list"
                  mode="inline"
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  style={{
                    width: !isMobile || isTablet ? 360 : "100%",
                  }}
                  selectedKeys={[selectedItemKey]}
                  onSelect={({ key, item }) => {
                    setSelectedItemKey(key); // Update the selected item key
                    router.push("/" + item?.props?.link);
                    onCloseSideNav();
                  }}
                  items={
                    item?.label?.toLowerCase() === "brands" && items.length
                      ? items // If we have filtered brands and items array is not empty, show them
                      : item?.label?.toLowerCase() === "brands"
                      ? // openedItems.find((item) => item.itemIndex === itemIndex)?.childItems.length === 0
                        // ? renderRemainingItems(openedItems.find((item) => item.itemIndex === itemIndex)?.childItems)
                        // : openedItems.find((item) => item.itemIndex === itemIndex)?.childItems
                        // renderRemainingItems(openedItems.find((item) => item.itemIndex === itemIndex)?.childItems)
                        // setBrandDependancy(2)
                        openedItems.find((item) => item.itemIndex === itemIndex)
                          ?.childItems
                      : openedItems.find((item) => item.itemIndex === itemIndex)
                          ?.childItems // Show original items
                  }
                />
              </>
            )}
          </Col>
        </Row>
      ))}
    </Drawer>
  );
}
