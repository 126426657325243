"use client";

import React, { useEffect, useState } from "react";

import viewMore from "../../../../../../assets/images/defaultImages/viewmoremenucatalogues.jpg";
import { useDispatch } from "react-redux";

import { Card, Row, Col, Typography, Image } from "antd";
import Link from "next/link";
import "./LatestCatalogue.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";

// import Image from "next/image";

const { Title } = Typography;

const LatestCatalogue = (props) => {
  const dispatch = useDispatch();
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const screenWidth = window.innerWidth;

  // Define image width and height based on screen width
  // let imageWidth, imageHeight;
  const imageWidthHeightHandler = () => {
    switch (true) {
      case screenWidth < 1024:
        setImageWidth(108);
        setImageHeight(108);
        break;
      case screenWidth >= 1024 && screenWidth < 1100:
        setImageWidth(135);
        setImageHeight(135);
        break;
      case screenWidth >= 1100 && screenWidth < 1150:
        setImageWidth(155);
        setImageHeight(155);
        break;
      case screenWidth >= 1150 && screenWidth < 1200:
        setImageWidth(160);
        setImageHeight(160);
        break;
      case screenWidth >= 1200 && screenWidth < 1250:
        setImageWidth(170);
        setImageHeight(170);
        break;
      case screenWidth >= 1250 && screenWidth < 1300:
        setImageWidth(175);
        setImageHeight(175);
        break;
      case screenWidth >= 1300 && screenWidth < 1350:
        setImageWidth(180);
        setImageHeight(180);
        break;
      case screenWidth >= 1350 && screenWidth < 1400:
        setImageWidth(180);
        setImageHeight(180);
        break;
      case screenWidth >= 1400 && screenWidth < 1500:
        setImageWidth(185);
        setImageHeight(185);
        break;
      case screenWidth >= 1500 && screenWidth < 1600:
        setImageWidth(195);
        setImageHeight(195);
        break;
      case screenWidth >= 1600 && screenWidth < 1700:
        setImageWidth(207);
        setImageHeight(207);
        break;
      case screenWidth >= 1700 && screenWidth < 1800:
        setImageWidth(220);
        setImageHeight(220);
        break;
      case screenWidth >= 1800 && screenWidth < 1900:
        setImageWidth(230);
        setImageHeight(230);
        break;
      case screenWidth >= 1900:
        setImageWidth(250);
        setImageHeight(250);
        break;
      default:
        setImageWidth(218);
        setImageHeight(218);
    }
  };

  useEffect(() => {
    imageWidthHeightHandler();
  }, [screenWidth]);

  return (
    <Col
      span={24}
      className="paddingRight20 paddingLeft20 latest-cataloguess-container"
      style={{
        height: "auto",
        paddingBottom: 10,
        backgroundColor: "#F1F1F1",
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      }}
      onClick={() => dispatch(setShowMegaMenu(false))}
    >
      <Title
        level={5}
        style={{
          color: props?.menuColor,
        }}
        className="margin0 paddingTop5 paddingBottom10"
      >
        Latest Catalog
      </Title>
      <Row ref={props?.megaMenuRef}>
        {props?.data?.slice(0, 11).map((item, key) => (
          <Col
            key={key}
            span={4}
            style={{
              marginBottom: 6,
            }}
          >
            <Link href={"/" + item?.brand_handle}>
              <div
                className="link-container"
                style={{ width: imageWidth, height: "auto" }}
              >
                <LazyLoadImage
                  alt={item.title}
                  src={item.banner_image?.cdn_link ?? ""}
                  width="100%"
                  height="auto"
                  // preview={false}
                  className="catalogue-image"
                />
                {/* <div className="explore-catalog">Explore Catalogue</div> */}
              </div>
            </Link>
          </Col>
        ))}

        <Col
          span={4}
          style={{
            marginBottom: 6,
          }}
        >
          <div
            className="link-container"
            style={{ width: imageWidth, height: "auto" }}
          >
            <Link href="/new-arrival">
              <LazyLoadImage
                alt="view more"
                src={viewMore?.src ?? ""}
                width="100%"
                height="auto"
                preview={false}
                className="catalogue-image"
              />
            </Link>
            {/* <div className="explore-catalog">Explore Catalogue</div> */}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default LatestCatalogue;
