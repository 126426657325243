"use client";
//? Library Imports ------------------------------------------------------------>
import { message } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useCallback } from "react";

export default function FacebookPixel({ userAgent, FacebookPixelId }) {
  //? Redux States --------------------------------------------------------------->
  const defaultCurrency = useSelector(
    (state) => state.multiLocation.defaultCurrency
  );

  //? Functions ------------------------------------------------------------------>
  const fb_pixel_ssr = useCallback(async () => {
    if (typeof window !== "undefined") {
      try {
        const body = {
          source: "website",
          event_name: "PageView",
          currency: "PKR",
          http_user_agent: userAgent,
          fbp: FacebookPixelId?.value,
          event_source_url: window.location.href,
          remote_addr: "255.255.255.255",
          customer_id: "",
          fn: "",
          ln: "",
          em: "",
          pn: "",
          gender: "",
          dob: "",
          region: "",
          city: "",
          zip: "",
        };

        const payload = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        };

        const SSR_FB_Pixel = await fetch(
          `https://api.soa-1.sanaullaonline.com/v1/page-view`,
          payload
        ).then((res) => res.json());

        if (!SSR_FB_Pixel.ok) {
          // message.error("SSR_FB_Pixel Api Failed!");
        console.error("Error posting FB_Pixel:", error.message);

          // throw new Error("SSR_FB_Pixel Api Failed!");
        }
        FB_Pixel = await SSR_FB_Pixel;
        // console.log(FB_Pixel);
      } catch (error) {
        // message.error("SSR_FB_Pixel Api Failed!");
        console.error("Error posting FB_Pixel:", error.message);
      }
    }
  }, [defaultCurrency, userAgent, FacebookPixelId?.value]);

  //? Life Cycles ---------------------------------------------------------------->
  useEffect(() => {
    fb_pixel_ssr();
  }, [fb_pixel_ssr]);

  useEffect(() => {
    !(function (b, e, f, g, a, c, d) {
      b.fbq ||
        ((a = b.fbq =
          function () {
            a.callMethod
              ? a.callMethod.apply(a, arguments)
              : a.queue.push(arguments);
          }),
        b._fbq || (b._fbq = a),
        (a.push = a),
        (a.loaded = !0),
        (a.version = "2.0"),
        (a.queue = []),
        (c = e.createElement(f)),
        (c.async = !0),
        (c.src = g),
        (d = e.getElementsByTagName(f)[0]),
        d.parentNode.insertBefore(c, d));
    })(window, document, "script", "//connect.facebook.net/en_US/fbevents.js");
    fbq("init", process.env.NEXT_PUBLIC_ANALYTICS_ID);
    fbq("track", "PageView");
  }, []);

  return null;
}
