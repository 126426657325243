import React from "react";

export default function NavigationBotData({ navigationData }) {
  return (
    <div>
      {navigationData?.map((navItem, index) => {
        if (navItem.layout_type === "layout_1") {
          return (
            <div key={index}>
              <h2>{navItem.label}</h2>
              {navItem.children.length > 0 ? (
                <div>
                  {navItem.children.map((childItem) => (
                    <div key={childItem.id}>
                      {/* <img
                        src={childItem?.image?.cdn_link}
                        alt={childItem?.name}
                      /> */}

                      {/* {childItem?.banner_image && (
                        <img
                          src={childItem.banner_image.cdn_link}
                          alt={`${childItem.name} Banner`}
                        />
                      )} */}
                      <a href={`/${childItem.handle}`}>
                        <h2>{childItem?.name}</h2>
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Brands Available</p>
              )}
            </div>
          );
        }
        if (navItem.layout_type === "layout_2") {
          return (
            <div key={index}>
              <h2>{navItem.label}</h2>
              {navItem.children.length > 0 ? (
                <div>
                  {/* <h2>Unstitched Dress Types:</h2> */}
                  <div>
                    {navItem.children.map((childItem) => (
                      <div key={childItem.label}>
                        <h2>{childItem.label}</h2>
                        <a href={`/${childItem.link}`}>
                          <h2> View {childItem.label}</h2>
                        </a>
                        {childItem.children?.length > 0 && (
                          <div>
                            {/* <h2>Sub-Collections:</h2> */}
                            <ul>
                              {childItem.children.map((subChildItem) => (
                                <li key={subChildItem.label}>
                                  <a href={`/${subChildItem.link}`}>
                                    <h2>{subChildItem.label}</h2>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No Categories Available</p>
              )}
            </div>
          );
        }

        if (navItem.layout_type === "layout_4") {
          return (
            <div key={index}>
              <h2>{navItem.label}</h2>
              {navItem.children.length > 0 ? (
                <div>
                  {navItem.children.slice(0, 10).map((childItem) => (
                    <div key={childItem.id}>
                      {/* <img
          src={childItem.banner_image.cdn_link}
          alt={childItem.title}
        /> */}
                      {/* <h2>{childItem.title}</h2> */}

                      {/* <p>Brand: {childItem.brand_handle}</p> */}
                      <a href={`/${childItem.handle}`}>
                        <h2>{childItem.title}</h2>
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No Collections Available</p>
              )}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}
