"use client";

//? Library Imports ------------------------------------------------------------>
import { Row, List, Typography } from "antd";
import { useDispatch } from "react-redux";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";

import Link from "next/link";

//? Assets --------------------------------------------------------------------->
import "./MegaMenu.scss";

//? Modules -------------------------------------------------------------------->
//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text, Title } = Typography;

export default function MegaMenu(props) {
  const dispatch = useDispatch();
  // console.log("MegaMenu Props", props);
  // const [subCategory, setSubCategory] = useState([]);
  // const [superSubCategory, setSuperSubCategory] = useState([]);

  // code for the mega menu category structure
  // const megaMenu = props?.data?.navigation_bar?.category_structure?.map(
  //   (cat, key) => {

  //     return {
  //       name: cat?.name,
  //       subCategory: cat?.sub_category,
  //       superSubCategory: cat?.sub_category[key]?.super_sub_category,
  //     };
  //   }
  // );

  return (
    <Row
      align="start"
      justify="start"
      ref={props?.megaMenuRef}
      className="MegaMenuContainer"
      style={{
        height: "auto",
        paddingBottom: 20,
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      }}
      onClick={() => dispatch(setShowMegaMenu(false))}
    >
      <List
        dataSource={props?.data}
        renderItem={(item) => (
          <List.Item
            style={{
              borderBottom: "none",
              //width: "10%",
              //padding: item.children?.length ? "5px 20px" : "0px 20px",
            }}
            className="paddingLeft20 paddingRight20"
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: " column",
                ///paddingRight: "20px",
                // alignItems: "normal",
              }}
            >
              <Link href={"/" + item?.link}>
                <Title
                  className="second-level"
                  level={5}
                  style={{
                    textAlign: "left",
                    alignItems: "start",
                    color: item?.text_color,
                  }}
                >
                  {item?.label}
                </Title>
              </Link>
              {item?.children?.length && (
                <div
                  className="third-level"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: " column",
                  }}
                >
                  {item?.children?.map((subCat, index) => (
                    <Link href={"/" + subCat?.link} key={index}>
                      <Text
                        style={{
                          color: subCat?.text_color,
                        }}
                      >
                        {subCat?.label}
                      </Text>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </List.Item>
        )}
      />
    </Row>
  );
}
