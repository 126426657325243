"use client";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";

export default function Providers({ children }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 14,
          borderRadius: 6,
          colorText: "#292929",
          colorPrimary: "#ff0d50", // Primary Color of Theme
          colorTextBase: "#B7B7B7",
          fontFamily: "Inter-Regular",
          fontWeight: 100,
        },
        components: {
          Typography: {
            colorText: "#000000", // Primary Text Color
            fontFamilyCode: "Inter-Regular",
            fontSizeHeading1: 24, // Title Font Size
            fontSizeHeading2: 22,
            fontSizeHeading3: 18,
            fontSizeHeading4: 16,
            fontSizeHeading5: 14,
          },
          Checkbox: {
            colorPrimary: "#ff0d50",
            // colorBorder: "#ff3f6c",
          },
          Radio: {
            borderRadius: 0,
            colorPrimaryHover: "#ff0d50",
          },
          Button: {
            colorText: "#000000", // Primary Text Color
            colorPrimary: "#ff0d50", // Primary Color of Button
            colorPrimaryHover: "#ff0d50", // Primary Color of Button
            borderRadiusLG: 30,
            colorBgContainerDisabled: "#b7b7b7", // Button Disabled Background Color
            // colorPrimaryBorder: "#b7b7b7",
            colorTextDisabled: "#ffffff", // Disabled Text Color
            // borderBorder: 0,
            // paddingContentHorizontal: 50, // Padding Left & Right
            // paddingContentVertical: 15, // Padding Top & Bottom
          },
          Input: {
            borderRadius: 6,
            colorBorder: "#D9D9D9",
            borderBorder: 0,
          },
          Select: {
            borderRadius: 0,
          },
        },
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => children}
        </PersistGate>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        {/* {children} */}
        {/* </PersistGate> */}
      </Provider>
    </ConfigProvider>
  );
}
