//? Library Imports ------------------------------------------------------------>
import { Col, Space, Image, Typography } from "antd";
import Link from "next/link";

//? Assets --------------------------------------------------------------------->
import {
  BiLogoTiktok,
  BiLogoTwitter,
  BiLogoYoutube,
  BiLogoFacebook,
  BiLogoWhatsapp,
  BiLogoInstagram,
  BiLogoPinterestAlt,
} from "react-icons/bi";

//? Modules -------------------------------------------------------------------->
//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
// const { Text } = Typography;

export default function SocialMediaFooter({ userAgent }) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  return (
    <Col span={24} className="flexCenter">
      <Space
        align="center"
        className="flexCenter width100 social-icons-on-mobile"
        size={isDesktop ? "middle" : "small"}
      >
        <Link href={"https://www.facebook.com/sanaullastore"}>
          <BiLogoFacebook fontSize={16} color="#5B5B5B" />
        </Link>
        <Link href={"https://www.twitter.com/sanaullastore"}>
          <BiLogoTwitter fontSize={16} color="#5B5B5B" />
        </Link>
        <Link href={"https://www.instagram.com/sanaullastore/"}>
          <BiLogoInstagram fontSize={16} color="#5B5B5B" />
        </Link>
        {/* <BiLogoTiktok fontSize={16} color="#5B5B5B" /> */}
        <Link href={"https://www.pinterest.com/sanaullastore/"}>
          <BiLogoPinterestAlt fontSize={16} color="#5B5B5B" />
        </Link>
        {/* <BiLogoWhatsapp fontSize={16} color="#5B5B5B" /> */}
        <Link href={"https://www.youtube.com/sanaullastore"}>
          <BiLogoYoutube fontSize={16} color="#5B5B5B" />
        </Link>

      </Space>
    </Col>
  );
}


