"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Row,
  Form,
  Card,
  Input,
  Button,
  Typography,
  notification,
} from "antd";
import Link from "next/link";
import axios from "axios";
import Image from "next/image";

//? Assets --------------------------------------------------------------------->
import icon1 from "../../../assets/images/footer/upperFooter/icon1.svg";
import icon2 from "../../../assets/images/footer/upperFooter/icon2.svg";
import icon3 from "../../../assets/images/footer/upperFooter/icon3.svg";
import icon4 from "../../../assets/images/footer/upperFooter/icon4.svg";
import icon5 from "../../../assets/images/footer/upperFooter/icon5.svg";
import icon6 from "../../../assets/images/footer/upperFooter/icon6.svg";
import newsletterImg from "../../../assets/images/footer/upperFooter/newsletter-img.png";

//? Modules -------------------------------------------------------------------->
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text, Title } = Typography;

export const UpperFooter = ({ footer, userAgent }) => {
  const { isMobile } = userAgent;

  // console.log("userAgent from upper footer", userAgent);
  const iconsSection = [
    { image: icon1, title: "Secure Payment", desc: "Multiple Plans" },
    { image: icon2, title: "We Ship", desc: "Internationally" },
    { image: icon3, title: "We Stock", desc: "Original Brands" },
    // { image: icon4, title: "Earn Points", desc: "Every Purchase" },
    { image: icon5, title: "Premium", desc: "Stitching Service" },
    { image: icon6, title: "Easy Exchange", desc: "Available" },
  ];

  const subscribeNewsLetter = (values) => {
    if (window.Moengage && window.Moengage.add_email) {
      window.Moengage.add_email(values?.email);
      window.Moengage.add_unique_user_id(values?.email);
    }
    axios
      .post(
        process.env.NEXT_PUBLIC_BACKEND + "/storefront/customer_news_letter",
        values
      )
      .then((response) => {
        notification["success"]({
          message: "Email Successfully Subscribed!",
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notification["error"]({
            message: "Email Already Registered!",
          });
        }

        console.log(err);
      });
  };

  return (
    <Row align="middle" justify="center" className="upper-footer">
      {!isMobile && (
        <Col
          span={24}
          style={{ display: "flex", justifyContent: "center", padding: "30px" }}
        >
          <div className="big-store-section">
            <Text>The Big Store</Text>
          </div>
        </Col>
      )}
      <Col
        xl={18}
        lg={18}
        md={18}
        sm={24}
        xs={24}
        order={isMobile && 2}
        className="service-container"
      >
        <Row className="service-container-wrapper">
          <Col span={24} className="programs-text-container">
            <Title
              style={{ fontFamily: "Inter-Regular", fontWeight: 100 }}
              level={4}
              className="marginBottom0 "
            >
              Our Programs
            </Title>
            <Text>
              Things you find at
              <span> Sanaulla</span>
            </Text>
          </Col>
          {/* <Col span={24} className="programs-container">
            <Link href="/pages/static/prime-customer">
              <Button className="gold-prime-btn">Become a Prime Member </Button>{" "}
            </Link>
            <Link href="/pages/static/reward-points-faq">
              <Button className="silver-service-btn">
                Earn Reward Points{" "}
              </Button>
            </Link>
          </Col> */}
        </Row>
      </Col>

      <Col
        span={24}
        order={isMobile && 1}
        className="upper-footer-icons-section"
      >
        <Row gutter={[20, 0]} style={{ justifyContent: "center" }}>
          {iconsSection?.map((item, index) => (
            <Col
              xl={4}
              lg={4}
              md={8}
              sm={8}
              xs={8}
              className={
                isMobile
                  ? "card-container card-container-mobile"
                  : "card-container"
              }
              key={index}
            >
              <Card
                hoverable
                style={{
                  margin: "4px",
                }}
              >
                <Image
                  key={index}
                  src={item?.image}
                  alt="icon"
                // preview={false}
                />
                <Title
                  style={{ fontFamily: "Inter-Regular", fontWeight: 100 }}
                  level={4}
                >
                  {item?.title}
                </Title>
                <Text>{item?.desc}</Text>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>

      {footer?.show_news_letter && (
        <Col
          lg={18}
          md={24}
          sm={24}
          xs={24}
          order={isMobile && 3}
          className="newsletter-box"
        >
          <Col>
            {!isMobile && (
              <Image
                src={newsletterImg}
                alt="newsletter image"
              // preview={false}
              />
            )}
            <Title
              level={3}
              style={{
                color: footer?.text_color || "#1E1E1E",
                fontFamily: "Inter-Regular",
                fontWeight: 100,
              }}
              className="margin0"
            >
              Be the First to Know
            </Title>
            <Text
              style={{
                color: footer?.text_color || "#1E1E1E",
              }}
              className="newsletter-msg"
            >
              Sign up for exclusive offers, style updates and more!
            </Text>
            <Form name="basic" size="large" onFinish={subscribeNewsLetter}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid email!",
                  },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email!",
                  },
                ]}
                style={{ marginBottom: "5px" }}
              >
                <Input
                  placeholder="@ Email  Address"
                  suffix={
                    !isMobile && (
                      <Button
                        htmlType="submit"
                        type="text"
                      //icon={<FaTelegramPlane />}
                      >
                        Submit
                      </Button>
                    )
                  }
                />
              </Form.Item>
              {isMobile && (
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              )}
            </Form>
          </Col>
        </Col>
      )}
    </Row>
  );
};
