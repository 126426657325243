"use client";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import usePath from "@/hooks/usePath/usePath";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import { setDefaultCountry } from "@/redux/Slices/multiLocationSlice";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { SessionProvider } from "next-auth/react";

//? Modules -------------------------------------------------------------------->
import Footer from "@/components/shared/Footer/Footer";
import { RootStyleRegistry } from "@/RootStyleRegistry";
import Navigation from "@/components/shared/Header/Navigation/Navigation";
import MobileHeader from "@/components/shared/Header/MobileHeader/MobileHeader";
import MobileBottomNavigation from "@/components/shared/Header/MobileBottomNavigation/MobileBottomNavigation";
import AccountsHeaderMobile from "@/components/MobileApp/Headers/AccountsHeaderMobile/AccountsHeaderMobile";
import PaymentGatewayFooter from "@/components/MobileApp/Footers/PaymentGatewayFooter/PaymentGatewayFooter";
import SocialMediaFooter from "@/components/MobileApp/Footers/SocialMediaFooter/SocialMediaFooter";
import NavigationBotData from "@/components/shared/Header/Navigation/NavigationBotData";

export default function LayoutsWrapper({
  children,
  userAgent,
  headerData,
  footerData,
  storefrontListData,
  myIpAddress
}) {
  //? Hooks ---------------------------------------------------------------------->
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isMobile, isTablet, isDesktop } = userAgent;
  useEffect(() => {
    Cookies.get("address") ||
      Cookies.set("address", myIpAddress, { expires: 365 });
  }, [])
  // useEffect(() => {
  //   if (!Cookies.get("website-access")) {
  //     document.querySelector("body").classList.add("hide-header");
  //     router.push('password-protected');
  //   }
  //   else {
  //     document.querySelector("body").classList.remove("hide-header");
  //   }
  //   return () => {
  //     document.querySelector("body").classList.remove("hide-header");
  //   };
  // }, [])
  // if (window?.performance) {
  //   console.log("Memory Usage: ", window.performance.memory);
  // }

  // console.log("Layout wrapper calling");

  return (
    <RootStyleRegistry>
      <SessionProvider>
        <div
          className="header"
          style={{
            display:
              path === "/cart/checkout" || path === "/login" ? "none" : "",
          }}
        >
          <div className="bot-navigation v-hidden">
            <NavigationBotData
              navigationData={
                headerData?.header?.navigation_bar?.main_navigation
              }
            />
          </div>
          {!isDesktop && (
            <>
              {path === "/account" && (
                <AccountsHeaderMobile
                  storefrontListData={storefrontListData}
                  data={headerData?.header}
                  userAgent={userAgent}
                />
              )}
              {path !== "/account" &&
                path !== "/cart/checkout" &&
                path !== "/search" &&
                path !== "/login" &&
                path !== "/password-protected" && (
                  <MobileHeader
                    data={headerData?.header}
                    userAgent={userAgent}
                    storefrontListData={storefrontListData}
                  />
                )}
            </>
          )}
          {isDesktop &&
            path !== "/cart/checkout" &&
            path !== "/password-protected" && (
              <Navigation
                storefrontListData={storefrontListData}
                data={headerData?.header}
                userAgent={userAgent}
              />
            )}
        </div>

        <div
          onMouseOver={() => dispatch(setShowMegaMenu(false))}
          className="mega-menu"
        >
          {children}

          {!isDesktop && path === "/search"
            ? null
            : isMobile &&
            path === "/" && (
              <MobileBottomNavigation
                storefrontListData={storefrontListData}
                data={headerData?.header}
                userAgent={userAgent}
              />
              // <></>
            )}
          {!isDesktop && path === "/search" ? null : path === "/account" ||
            path === "/login" ? (
            <>
              {!isDesktop ? <SocialMediaFooter userAgent={userAgent} /> : null}
              <PaymentGatewayFooter userAgent={userAgent} />
            </>
          ) : !isDesktop && path === "/search" ? null : path !==
            "/cart/checkout" && path !== "/password-protected" ? (
            <Footer data={footerData?.footer} userAgent={userAgent} />
          ) : null}
        </div>
      </SessionProvider>
    </RootStyleRegistry>
  );
}
