/* eslint-disable @next/next/no-img-element */
"use client";
//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { debounce } from "lodash";
import _ from "lodash";
import { useEffect, useCallback, useRef, useState, Suspense } from "react";
import Cookies from "js-cookie";
import { Col, Row, Spin, Space, Typography, Button } from "antd";
import MariaBImage from "../../../assets/images/brands/mariaB.svg";
import manara from "../../../assets/images/brands/manara.svg";
import maryam from "../../../assets/images/brands/maryam.svg";
import mohagni from "../../../assets/images/brands/mohagni.svg";
import mashq from "../../../assets/images/brands/mashq.svg";

//? Assets --------------------------------------------------------------------->
import {
  SearchOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./MegaSearch.scss";

//? Modules -------------------------------------------------------------------->
import Loader from "../Loader/Loader";
import ProductCard from "../ProductsCarousel/ProductCard/ProductCard";
import SearchForMobile from "../../../components/shared/Search/MobileSearch/MobileSearchh";
import { GiMechanicGarage } from "react-icons/gi";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MegaSearchCustom({
  Loading,
  setLoading,
  userAgent,
  ActivePage,
  setSearchQuery,
  setcategorySearchFlag,
  SearchQuery,
  setActivePage,
  SearchResults,
  ShowMegaSearch,
  setSearchResults,
  setShowMegaSearch,
  SearchedProducts,
  setSearchedProducts,
  setOnlyChangeProductsFlag,
  mainLoading,
  productLoader,
  setProductLoader,
  loadMoreButtonFlag,
  setLoadMoreButtonFlag,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const SearchRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const [hoverStateFlag, setHoverStateFlag] = useState(true);
  const { isMobile, isTablet, isDesktop } = userAgent;
  const [showMobileSearchBar, setShowMobileSearchBar] = useState("block");
  const [hoverQueryValue, setHoverQueryValue] = useState("");
  const [loadMoreButtonLoading, setLoadMoreButtonLoading] = useState(false);

  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  //? Redux States --------------------------------------------------------------->
  //? States --------------------------------------------------------------------->
  //? Functions ------------------------------------------------------------------>
  const handleClickOutside = useCallback(
    (event) => {
      if (SearchRef.current && !SearchRef.current.contains(event.target)) {
        setShowMegaSearch(false);
        document.body.classList.remove("disableScroll");
      }
    },
    [setShowMegaSearch]
  );
  //check for escape key press
  const handleKeyPress = (event) => {
    if (event.key === "Escape" || event.key === "Esc") {
      setShowMegaSearch(false);
    }
  };

  const delayedSearchQuery = debounce((query) => {
    // setHoverQueryValue(query);
    setOnlyChangeProductsFlag(true);
    setActivePage(1);
    setcategorySearchFlag(true);
    setSearchQuery(query);
  }, 2000);

  const searchMoreProducts = useCallback(() => {
    setLoadMoreButtonLoading(true);
    const pageNo = ActivePage + 1;
    setActivePage(pageNo);

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${pageNo}&q=` +
          SearchQuery +
          "&limit=18" +
          `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        if (response?.data?.results?.length > 17) {
          setLoadMoreButtonFlag(true);
        } else {
          setLoadMoreButtonFlag(false);
        }
        setSearchedProducts((prevProducts) => {
          // Create a Set to store unique products
          const uniqueProducts = new Set([
            ...prevProducts,
            ...(response?.data?.results || []),
          ]);

          // Convert the Set back to an array
          return [...uniqueProducts];
        });

        setTimeout(() => {
          setLoading(false);
          setLoadMoreButtonLoading(false);
        }, 1500);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
        setLoadMoreButtonLoading(false);
        setLoading(false);
      });
  }, [ActivePage, SearchQuery, setActivePage, setLoading, setSearchedProducts]);

  //? Life Cycles ---------------------------------------------------------------->
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [handleClickOutside]);

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("keydown", handleKeyPress);

    // Clean up: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (ShowMegaSearch) {
      document.body.classList.add("search-opened");
      document.body.classList.add("disableScroll");
    } else {
      document.body.classList.remove("search-opened");
      document.body.classList.remove("disableScroll");
    }
    return () => {
      document.body.classList.remove("disableScroll");
    };
  }, [ShowMegaSearch]);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef?.current;

    const handleScroll = () => {
      // Get the current scroll position
      const scrollPosition = scrollableDiv?.scrollTop;

      // Get the maximum scroll position (total scrollable height - div height)
      const maxScrollPosition =
        scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;

      // Check if the scroll position has reached the end

      if (
        scrollPosition + 20 >= maxScrollPosition &&
        SearchedProducts?.length < SearchResults?.count
      ) {
        // Call your other function here
        // console.log("Reached the end of scroll");
        // setLoading(true);
        // setTimeout(() => {
        //   searchMoreProducts();
        // }, 1000);
      }
      setLoading;
    };

    // Add a scroll event listener to the div
    scrollableDiv?.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [
    setLoading,
    SearchResults?.count,
    SearchedProducts?.length,
    searchMoreProducts,
  ]);

  return (
    ShowMegaSearch && (
      <Col
        span={24}
        ref={SearchRef}
        className="paddingLeft10 paddingRight10 positionRelative"
      >
        <div className="overlay"></div>
        {/* //if(window.innerwidth <= 1024 )
          render this
        */}
        {isMobile || isTablet ? (
          <Row
            className="MegaSearchCustom"
            // display={{ xs: "flex", md: "none" }}
          >
            <Col flex="100%" style={{ overflowY: "scroll", height: "100vh" }}>
              <div>
                <SearchForMobile
                  userAgent={{ isMobile, isTablet, isDesktop, userAgent }}
                  setShowMobileSearchBar={setShowMobileSearchBar}
                  setShowMegaSearch={setShowMegaSearch}
                />
              </div>
            </Col>
          </Row>
        ) : isDesktop && !mainLoading ? (
          <Row
            Row
            className="MegaSearchCustom"
            // display={{ xs: "none", md: "flex" }}
          >
            {/* <Spin spinning={true} delay={500}> */}
            <Col
              flex="20%"
              className="scroll"
              style={{ overflowY: "auto", height: "90vh", overflowX: "hidden" }}
            >
              {/* <Col className="paddingLeft15 paddingTop20 mega-search-menu-brand-flex-list"> */}
              <Row gutter={[5, 5]} className="paddingLeft15 paddingTop20">
                {SearchResults &&
                  SearchResults?.brand_aggs?.map((item, index) => {
                    if (
                      index < 11 &&
                      item.logo_image !== "" &&
                      item.logo_image !== null
                    ) {
                      return (
                        <Link
                          href={`/${item.handle}`}
                          key={index}
                          onClick={() => {
                            setShowMegaSearch(false);
                          }}
                        >
                          <Col
                            span={8}
                            align="center"
                            className="align-self-center"
                            key={index}
                          >
                            <img
                              src={item.logo_image}
                              alt="brand logo"
                              width={"80px"}
                            />
                          </Col>
                        </Link>
                      );
                    }
                  })}
                {/* <Col span={8} align="center" className="align-self-center">
                    <Image src={MariaBImage} width={0}
                      height={0}
                      sizes="100vw" alt="brand logo" />
                  </Col>
                  <Col span={8} align="center" className="align-self-center">
                    <Image src={manara} width={0}
                      height={0}
                      sizes="100vw" alt="brand logo" />
                  </Col>
                  <Col span={8} align="center" className="align-self-center">
                    <Image src={mohagni} width={0}
                      height={0}
                      sizes="100vw" alt="brand logo" />
                  </Col>
                  <Col span={8} align="center" className="align-self-center">
                    <Image src={maryam} width={0}
                      height={0}
                      sizes="100vw" alt="brand logo" />
                  </Col>
                  <Col span={8} align="center" className="align-self-center">
                    <Image src={mashq} width={0}
                      height={0}
                      sizes="100vw" alt="brand logo" />
                  </Col> */}
              </Row>
              {/* </Col> */}
              <Col className="borderBottom padding15" span={24}>
                <Text className="font14Bold">Related Categories</Text>
              </Col>

              <Col className="padding15" span={24}>
                <Space direction="vertical" size="small">
                  {SearchResults?.collection_aggs?.map((category, index) => {
                    return (
                      <Link
                        className="mega-search-categories-link-div"
                        key={index}
                        href={category?.handle}
                        onClick={() => setShowMegaSearch(false)}
                        onMouseEnter={() => {
                          // if (hoverStateFlag) {
                          setOnlyChangeProductsFlag(true);
                          // setSearchQuery(category?.name);
                          setActivePage(1);
                          setcategorySearchFlag(true);
                          // setHoverQueryValue(category?.name);
                          // setHoverStateFlag(false);
                          delayedSearchQuery(category?.name);
                          // hoverStateFlagTimeout();
                          // }
                        }}
                      >
                        <Space key={index} className="width100">
                          <SearchOutlined />
                          <Text className="font12 mega-search-categories-link">
                            {category?.name}
                          </Text>
                        </Space>
                      </Link>
                    );
                  })}
                </Space>
              </Col>

              {/* TODO: trending tab is commented out here by abdullah-dev */}

              {/* <Col className="borderBottom padding15" span={24}>
                  <Text className="font14Bold">Trendinga</Text>
                </Col> */}

              {/* <Col className="padding15" span={24}>
                  <Space direction="vertical" size="small">
                    {SearchResults?.collection_aggs?.map((collection, index) => {
                      return (
                        <Link
                          key={index}
                          href={collection.handle}
                          // style={{ padding: "2px" }}
                          onClick={() => setShowMegaSearch(false)}
                          //
                          onMouseEnter={() => {
                            // if (hoverStateFlag) {
                            setOnlyChangeProductsFlag(true);
                            // setSearchQuery(collection?.name);
                            setActivePage(1);
                            setcategorySearchFlag(true);
                            // setHoverStateFlag(false);
                            // setHoverQueryValue(collection?.name);
                            delayedSearchQuery(collection?.name);
                            // hoverStateFlagTimeout();
                            // }
                          }}
                        >
                          <Text className="font12 mega-search-categories-link">{collection?.name}</Text>
                        </Link>
                      );
                    })}
                  </Space>
                </Col> */}
            </Col>

            <Col
              flex="80%"
              className="borderLeft searchProducts positionRelative paddingRight15"
            >
              {SearchedProducts?.length ? (
                <Row
                  gutter={[0, 12]}
                  className="positionRelative row"
                  // style={{ height: "100vh" }}
                >
                  <Col span={24}>
                    <Space className="flexBetween width100 backgroundLight borderBottom padding15 paddingBottom10 paddingLeft0 marginLeft15 ">
                      {SearchQuery.length === 0 ? (
                        <Text className="font14Bold"> Latest Products </Text>
                      ) : (
                        <Text className="font14Bold"> Products </Text>
                      )}
                      <CloseCircleOutlined
                        className="closeIcon"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          cursor: "pointer",
                          background: "black",
                          borderRadius: "25px",
                        }}
                        onClick={() => setShowMegaSearch(false)}
                      />
                    </Space>
                  </Col>

                  <Col
                    span={24}
                    ref={scrollableDivRef}
                    className="paddingLeft15 paddingRight15 productsListing scroll"
                  >
                    {!productLoader ? (
                      <Row gutter={[10, 15]} className="positionRelative">
                        <div
                          style={{
                            height: "80%",
                            maxHeight: "80%",
                            // overflowY: "scroll",
                          }}
                        >
                          <Row
                            gutter={[10, 15]}
                            className="positionRelative product-grid"
                          >
                            {SearchedProducts?.map((product, index) => {
                              return (
                                <Col
                                  // i have applied check here for 1 single product from elastic search usually
                                  // this is for SKU search
                                  // might have to change code here in the near future
                                  xxl={SearchedProducts?.length === 1 ? 12 : 4} //4 changes to 12 here too
                                  xl={SearchedProducts?.length === 1 ? 12 : 6} //6 changes to 12 here
                                  lg={SearchedProducts?.length === 1 ? 12 : 6} //6 changes to 12 here too
                                  md={SearchedProducts?.length === 1 ? 12 : 4} //12
                                  sm={6} //6
                                  key={index}
                                  className="padding0"
                                >
                                  <Link
                                    key={index}
                                    href={product?.handle}
                                    onClick={() => setShowMegaSearch(false)}
                                  >
                                    <ProductCard
                                      product={product}
                                      userAgent={userAgent}
                                      megaSearchMenuCheck
                                    />
                                  </Link>
                                </Col>
                              );
                            })}

                            {loadMoreButtonLoading ? (
                              <Col span={24} className="flexCenter">
                                <Loader />
                              </Col>
                            ) : (
                              loadMoreButtonFlag && (
                                <Col sm={24} className="flexCenter padding20">
                                  <Button
                                    type="primary"
                                    className="search-load-more-button"
                                    outline
                                    onClick={() => {
                                      searchMoreProducts();
                                    }}
                                  >
                                    Load More
                                  </Button>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </Row>
                    ) : (
                      <Col sm={24} className="flexCenter">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 50,
                                color: "#ff000",
                              }}
                              spin
                            />
                          }
                        />
                      </Col>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row
                  gutter={[0, 12]}
                  className="positionRelative row paddingLeft15"
                >
                  <Col span={24}>
                    <Space className="notFoundSpace flexBetween width100 backgroundLight borderBottom padding15 paddingBottom10 paddingLeft0 ">
                      <Space
                        className="width100"
                        align="center"
                        style={{
                          width: "100%",
                          padding: "5px",
                          // maxWidth: "97%",
                          background: "#F8D7DA",
                        }}
                      >
                        <ExclamationCircleOutlined className="ExclamationCircle" />

                        <Text className="font14 width100">
                          Sorry, No Result Found
                        </Text>
                      </Space>

                      <CloseCircleOutlined
                        className="closeIcon marginRight10"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          cursor: "pointer",
                          background: "black",
                          borderRadius: "25px",
                        }}
                        onClick={() => setShowMegaSearch(false)}
                      />
                    </Space>
                  </Col>

                  <Col span={24} className="productsListing">
                    {SearchResults?.featured_collections?.length ? (
                      <>
                        <Col
                          span={24}
                          style={{ width: "77%" }}
                          className="backgroundLight borderBottom padding15 paddingLeft0"
                        >
                          <Text className="font14Bold">
                            Featured Collection
                          </Text>
                        </Col>

                        <Col span={24} className="paddingLeft15 paddingRight15">
                          <Row gutter={[10, 15]} className="positionRelative">
                            {SearchResults?.featured_collections?.map(
                              (collection, index) => {
                                return (
                                  <Col
                                    key={index}
                                    flex="14.2%"
                                    className="padding0"
                                  >
                                    <Link
                                      href={collection?.link}
                                      onClick={() => setShowMegaSearch(false)}
                                    >
                                      <Space
                                        direction="vertical"
                                        align="center"
                                      >
                                        <img
                                          width={112}
                                          height={111}
                                          src={collection.img}
                                          alt={
                                            collection?.alt_text
                                              ? collection?.alt_text
                                              : "Collection"
                                          }
                                        />
                                        <Text className="font18">
                                          {collection?.title
                                            ? collection?.title
                                            : "N/A"}
                                        </Text>
                                      </Space>
                                    </Link>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </Col>
                      </>
                    ) : null}

                    {SearchResults?.catalogue_lists?.length ? (
                      <>
                        <Col
                          span={24}
                          style={{ width: "77%", marginTop: "0px" }}
                          className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                        >
                          <Text className="font14Bold">New Catalog</Text>
                        </Col>

                        <Col span={24} className="paddingLeft15 paddingRight15">
                          <Row gutter={[10, 15]} className="positionRelative">
                            {SearchResults?.catalogue_lists?.map(
                              (catalogue, index) => {
                                return (
                                  <Col
                                    span={4}
                                    className="padding0"
                                    key={index}
                                  >
                                    <Link
                                      href={catalogue?.collection_handle}
                                      onClick={() => setShowMegaSearch(false)}
                                    >
                                      <img
                                        width={163}
                                        height={180}
                                        alt="Catalogue"
                                        src={catalogue?.cdn_link}
                                      />
                                    </Link>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </Col>
                      </>
                    ) : null}
                  </Col>
                </Row>
              )}
            </Col>
            {/* </Spin> */}
          </Row>
        ) : (
          <Row
            Row
            className="MegaSearchCustom spinner-flex-justify-content-center"
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                    color: "#FE4",
                  }}
                  spin
                />
              }
            />
          </Row>
        )}
      </Col>
    )
  );
}
