"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Row, Col, Image, Input, Typography, AutoComplete } from "antd";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";

import "./Brands.scss";
import Link from "next/link";
import { useRouter } from "next/navigation";

const { Search } = Input;
const { Title } = Typography;

export default function Brands(props) {
  const dispatch = useDispatch();

  const [brands, setBrands] = useState(props?.data);
  const [activeLetter, setActiveLetter] = useState(null);
  const alphabetRefs = useRef({});
  const [brandsAlphabet, setBrandsAlphabet] = useState([]);
  const [brandsGrid, setBrandsGrid] = useState({
    AtoF: [],
    GtoM: [],
    NtoS: [],
    TtoZ: [],
  });
  const [showSearchResults, setShowSearchResults] = useState(false);

  const [searchResult, setSearchResult] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [filteredBrands, setFilteredBrands] = useState([]);

  const megaMenu = props?.data?.navigation_bar?.category_structure?.map(
    (cat, key) => {
      return {
        name: cat?.name,
        subCategory: cat?.sub_category,
        superSubCategory: cat?.sub_category[key]?.super_sub_category,
      };
    }
  );

  const router = useRouter();

  const myRef = useRef(null);
  const executeScroll = (letter) => {
    if (alphabetRefs.current[letter]) {
      alphabetRefs.current[letter].scrollIntoView({ behavior: "smooth" });
      setActiveLetter(letter);
    }
  };
  const alphabetList = useMemo(
    () => [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
    []
  );

  const brandsVsAlphabet = useCallback(() => {
    var brands_alphabet = [];
    for (const letter of alphabetList) {
      brands_alphabet.push({ letter: letter, brands: [] });
    }
    for (const brand of brands) {
      for (const item of brands_alphabet)
        if (brand.name.substring(0, 1).toLowerCase() === item.letter) {
          item.brands.push(brand);
        }
    }

    setBrandsAlphabet(brands_alphabet);

    setBrandsGrid({
      AtoF: brands_alphabet.slice(0, 6),
      GtoM: brands_alphabet.slice(6, 13),
      NtoS: brands_alphabet.slice(13, 19),
      TtoZ: brands_alphabet.slice(19, 26),
    });
  }, [alphabetList, brands]);

  const onSearchBrands = (value) => {
    setInputValue(value);
    const results = [];
    for (const item of brandsAlphabet) {
      if (item.brands.length > 0) {
        for (const brand of item.brands) {
          if (brand?.name?.toLowerCase().includes(value.toLowerCase())) {
            results.push({ value: brand.name, handle: brand.handle });
          }
        }
      }
    }
    setSearchResult(results);

    // Determine whether to show search results or brand alphabets
    setShowSearchResults(!!value);
  };

  const onSelectBrand = (value, option) => {};

  useEffect(() => {
    if (brands) {
      brandsVsAlphabet();
    }
  }, [brands, brandsVsAlphabet]);

  useEffect(() => {
    alphabetList.forEach((alpha) => {
      alphabetRefs.current[alpha] = document.getElementById(alpha);
    });
  });

  useEffect(() => {
    if (brands) {
      handleMoreBrands();
    }
  }, [brands]);

  const handleMoreBrands = () => {
    const indexesToAdd = brands.slice(
      filteredBrands.length,
      filteredBrands.length + 32
    );
    setFilteredBrands((prevArray) => [...prevArray, ...indexesToAdd]);
  };

  return (
    <Row
      align="start"
      justify="start"
      className="brands-alphabet-search-container"
      ref={props?.megaMenuRef}
      gutter={[10, 0]}
      style={{
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      }}
    >
      <Col span={1} />
      <Col span={5} className="brands-search-component">
        <Title
          level={5}
          style={{
            color: props?.menuColor,
          }}
          className="paddingBottom5 font14 poppinsBold "
        >
          Search Your Brand
        </Title>
        <Row>
          <Col span={24} className="brands-search-bar">
            <AutoComplete
              autoFocus={true}
              filterOption={(inputValue, option) =>
                option?.value
                  ?.toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              popupClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={200}
              style={{ width: 215 }}
              options={[]} // Set this to an empty array
              onSearch={onSearchBrands}
              onSelect={(value, option) => {
                router.push(`/${option?.handle}`);
                setInputValue(value);
              }}
              value={inputValue}
            >
              <Search
                placeholder="Search..."
                style={{
                  // width: 255,
                  background: "#ffffff",
                }}
                bordered={false}
                allowClear
              />
            </AutoComplete>
          </Col>
          <Col span={20} className="brands-with-letters">
            {/* onClick={() => dispatch(setShowMegaMenu(false))} */}
            <Row>
              <Col
                span={21}
                className="brands-vs-letter"
                onClick={() => dispatch(setShowMegaMenu(false))}
              >
                {showSearchResults
                  ? searchResult?.map((result, key) => {
                      return (
                        <div key={key}>
                          {/* <p>{result.value}</p> */}
                          <ul>
                            <li>
                              <Link href={"/" + result.handle}>
                                {result.value}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  : brandsAlphabet?.map((brand, key) => {
                      return (
                        <div ref={myRef} id={brand.letter} key={key}>
                          <p>{brand.letter.toLocaleUpperCase()}</p>
                          <ul>
                            {brand.brands?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <Link href={"/" + item.handle}>
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
              </Col>
              <Col span={3} className="alphabet-list">
                <ul>
                  {alphabetList?.map((alpha, key) => {
                    return (
                      <li
                        onClick={() => executeScroll(alpha)}
                        key={key}
                        className={activeLetter === alpha ? "active" : ""}
                      >
                        {alpha.toUpperCase()}
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={18}>
        {/* <Row>
          {[1, 2, 3, 4].map((value, index) => {
            let totalBrandImgs = 0;

            return (
              <Col span={6} className="brand-imgs-section" key={index}>
                <Row
                  justify="space-evenly"
                  onClick={() => dispatch(setShowMegaMenu(false))}
                >
                  <Col span={24}>
                    <Title
                      level={5}
                      style={{
                        color: props?.menuColor,
                      }}
                      className="paddingBottom5 font14 poppinsBold textCenter"
                    >
                      {index === 0
                        ? "A - F"
                        : index === 1
                          ? "G - M"
                          : index === 2
                            ? "N - S"
                            : "T - Z"}
                    </Title>
                  </Col>
                  {Object.values(brandsGrid)[index]?.map(
                    (brandAlphabetObj, key) => {
                      return brandAlphabetObj?.brands
                        .slice(0, 1)
                        ?.map((brand, index) => {
                          totalBrandImgs = totalBrandImgs + 1;

                          return (
                            index < 8 && (
                              <Col
                                span={11}
                                className="brand-imgs-container"
                                key={index}
                              >
                                <Link href={"/" + brand.handle}>
                                  <Image
                                    src={brand?.image?.cdn_link}
                                    preview={false}
                                    alt={brand.name}
                                  />
                                </Link>
                              </Col>
                            )
                          );
                        });
                    }
                  )}
                  {Object.values(brandsGrid)[index]?.map(
                    (brandAlphabetObj, key) => {
                      return brandAlphabetObj?.brands
                        .slice(1, 2)
                        ?.map((brand, index) => {
                          totalBrandImgs = totalBrandImgs + 1;
                          if (totalBrandImgs < 9) {
                            return (
                              <Col
                                span={11}
                                className="brand-imgs-container"
                                key={index}
                              >
                                <Link href={"/" + brand.handle}>
                                  <Image
                                    src={brand?.image?.cdn_link}
                                    preview={false}
                                    alt={brand.name}
                                  />
                                </Link>
                              </Col>
                            );
                          }
                        });
                    }
                  )}
                </Row>
              </Col>
            );
          })}
        </Row> */}
        <div className="brands-logo-main">
          <div className="brands-logo-inner">
            {filteredBrands?.length > 0 &&
              filteredBrands?.map((item, index) => (
                <Link
                  href={"/" + item?.handle}
                  className="brand-logo-item"
                  key={index}
                >
                  <img src={item?.image?.cdn_link} alt={item?.name} />
                </Link>
              ))}
            <div className="show-more">
              <span
                onClick={() => {
                  handleMoreBrands();
                }}
              >
                {" "}
                Show More{" "}
              </span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
