"use client";
import React, { useEffect } from "react";

const FreshworksWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
      window.fwSettings = {
        'widget_id': 154000002131
      };
      if (!window.FreshworksWidget) {
        window.FreshworksWidget = function() {
          window.FreshworksWidget.q.push(arguments);
        };
        window.FreshworksWidget.q = [];
      };
    `;
    document.head.appendChild(script);

    const freshworksScript = document.createElement("script");
    freshworksScript.src =
      "https://widget.freshworks.com/widgets/154000002131.js";
    freshworksScript.async = true;
    freshworksScript.defer = true;
    document.head.appendChild(freshworksScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(freshworksScript);
    };
  }, []);

  return null;
};

export default FreshworksWidget;
