import { Modal, Box } from "@mui/material";

export default function MegaSearchh({
  IsModalOpen,
  setIsModalOpen,
  SearchResults,
}) {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={IsModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      fullWidth
      maxWidth="lg" // Set the maximum width as needed
    >
      <Box
        sx={{
          margin: "50px 10px 10px 10px", // 10px margin from all sides
          display: "flex",
          backgroundColor: "white", // White background
        }}
      >
        <Box
          sx={{
            flexBasis: "22%", // Left column width (22%)
            padding: "10px", // Optional padding for left column
          }}
        >
          {/* Content of the left column */}
          <h2 id="modal-title">Left Column</h2>
          {/* Add content here */}
        </Box>
        <Box
          sx={{
            flex: 1, // Right column takes the remaining space
            padding: "10px", // Optional padding for right column
          }}
        >
          {/* Content of the right column */}
          <h2 id="modal-title">Right Column</h2>
          <ul>
            {SearchResults.map((result) => (
              <li key={result.id}>{result.name}</li>
            ))}
          </ul>
        </Box>
      </Box>
    </Modal>
  );
}
