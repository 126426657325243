"use client";

// Library Imports
// import axios from "axios";
import { useEffect, useState } from "react";
import { Row, Col, Typography, Space } from "antd";

// Assets, Media & StyleSheets 🚀
import "./Footer.scss";

// Footer logos for customer policies and payment options

import {
  FaTiktok,
  FaYoutube,
  FaTwitter,
  FaWhatsapp,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
} from "react-icons/fa";

import visa from "../../../assets/images/footer/1f4baa60-visa-logo-old.png";
import applePay from "../../../assets/images/footer/Apple_Pay-Logo.wine.svg";
import paypal from "../../../assets/images/footer/2560px-PayPal_logo.svg.png";
import mastercard from "../../../assets/images/footer/MasterCard_Logo.svg.png";
import ginkgo_logo from "../../../assets/images/footer/ginkgo_logo.png";
import comverse_logo from "../../../assets/images/footer/comverse_logo.png";
import comverse_ginkgo_logo from "../../../assets/images/footer/comverse_ginkgo_logo.svg";

import AddedFooter from "./AddedFooter";
import { UpperFooter } from "./UpperFooter";
import Link from "next/link";
import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import Image from "next/image";
import { usePathname } from "next/navigation";

// Library Constants
const { Text, Title } = Typography;

export default function Footer({ data, userAgent }) {
  // const [colWidth, setColWidth] = useState(0);
  const colWidth = 24 / (data?.navigations?.length + 1);

  // const { isTablet, isMobile } = MediaQueryHook();

  const { isDesktop, isTablet, isMobile } = userAgent;
  const pathName = usePathname();

  // console.log("footer media query", isMobile, isTablet);
  // const getFooter = () => {
  //   axios
  //     .get(process.env.REACT_APP_BACKEND_HOST + "/storefront/footer")
  //     .then((res) => {
  //       setFooter(res?.data?.footer);
  //       const colCount = 1; //res?.data?.footer?.show_news_letter === true ? 2 : 1;
  //       setColWidth(24 / (res?.data?.footer?.navigations?.length + colCount));
  //     })
  //     .catch((err) => console.log("Footer Api Error: ", err));
  // };

  // useEffect(() => {
  //   getFooter();
  // }, []);

  // useEffect(() => {
  //   setColWidth(24 / (data?.navigations?.length + 1));
  // }, []);

  return (
    <div className="paddingTop40 footer-container">
      {pathName === "/" ? (
        <Col className="added-footer-container" span={24}>
          <AddedFooter footer={data} userAgent={userAgent} />
        </Col>
      ) : null}
      <div className={!isDesktop ? "footer footer-mobile" : "footer"}>
        <UpperFooter footer={data} userAgent={userAgent} />
        <Col
          span={24}
          className="flexColumn flexCenter nav-links-container-wrapper"
        >
          <Row
            style={{
              backgroundColor: data?.background_color,
            }}
          >
            {data?.navigations?.map((item, index) => (
              <Col
                xl={colWidth}
                lg={colWidth}
                md={colWidth}
                sm={24}
                xs={24}
                key={index}
                className="nav-links-container"
              >
                <Title
                  level={4}
                  style={{
                    color: data?.text_color,
                    fontFamily: "Inter-Regular",
                    fontWeight: 100,
                  }}
                >
                  {item.title}
                </Title>
                <Row className="flex-links-wrap">
                  {item?.menu?.map((nav, index) => (
                    <Col className="nav-link" key={index}>
                      <Link
                        href={nav?.link ? nav?.link : "/"}
                        style={{ color: data?.text_color }}
                      >
                        {nav.label}
                      </Link>
                      &nbsp;
                      {(isMobile || isTablet) &&
                        index !== item.menu?.length - 1 && <span>|</span>}
                      &nbsp;
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
            <Col xl={colWidth} lg={colWidth} md={24} sm={24} xs={24}>
              <Row className=" footer-icons">
                <Col span={24} className="social-icons">
                  {isDesktop && (
                    <Title
                      style={{ fontFamily: "Inter-Regular", fontWeight: 100 }}
                      level={4}
                    >
                      Social Links:
                    </Title>
                  )}
                  <Space size={"middle"} direction={"horizontal"}>
                    <Link href={"https://www.facebook.com/sanaullastore"}>
                      <FaFacebookF />
                    </Link>
                    <Link href={"https://www.twitter.com/sanaullastore"}>
                      <FaTwitter />
                    </Link>

                    <Link href={"https://www.instagram.com/sanaullastore/"}>
                      <FaInstagram />
                    </Link>

                    {/* <Link href={"/"}>
                      <FaTiktok />
                    </Link> */}

                    <Link href={"https://www.pinterest.com/sanaullastore/"}>
                      <FaPinterestP />
                    </Link>

                    {/* <Link href={"/"}>
                      <FaWhatsapp />
                    </Link> */}

                    <Link href={"https://www.youtube.com/sanaullastore"}>
                      <FaYoutube />
                    </Link>
                    <Link
                      href={
                        isMobile ? "https://wa.me/923158337822" : "https://www.whatsapp.com/channel/0029Va8C12I96H4Ju5U3U10b"
                      }
                    >
                      <FaWhatsapp />
                    </Link>
                  </Space>
                </Col>

                {!isDesktop && (
                  <Col span={24} className="copyright-container">
                    <Text className="copyright-text">
                      Sanaulla Store - Copyright © 2024
                    </Text>
                  </Col>
                )}
                <Col span={24} className="payment-options">
                  {isDesktop && (
                    <Title
                      style={{ fontFamily: "Inter-Regular", fontWeight: 100 }}
                      level={4}
                    >
                      Payment Options:
                    </Title>
                  )}
                  <Space size={"middle"} direction={"horizontal"}>
                    <Link href={"/"}>
                      <Image src={applePay} alt="apple icon" />
                    </Link>
                    <Link href={"/"}>
                      <Image src={paypal} alt="paypal icon" />
                    </Link>
                    <Link href={"/"}>
                      <Image src={visa} alt="visa icon" />
                    </Link>
                    <Link href={"/"}>
                      <Image src={mastercard} alt="mastercard icon" />
                    </Link>
                  </Space>
                </Col>
                <Col span={24} className="payment-options">
                  {/* {isDesktop && (
                    <Title
                      style={{ fontFamily: "Inter-Regular", fontWeight: 100 }}
                      level={4}
                    >
                      Contact
                    </Title>
                  )} */}
                  <Row>
                    <Link href="mailto:care@cs.sanaullastore.com">
                      <Text>Email: care@cs.sanaullastore.com </Text>
                    </Link>
                  </Row>
                  <Row>
                    <Link href="tel:+923111444910">
                      <Text>Call: 923-111-444-910 ext 0 or 4 </Text>
                    </Link>
                  </Row>
                  <Row>
                    {" "}
                    <Text>(10 AM- 7 PM) </Text>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          {isDesktop && (
            <Col span={24} className="copyright-container">
              <Text className="copyright-text">
                Sanaulla Store - Copyright © 2024
              </Text>
            </Col>
          )}
        </Col>
      </div>

      {isDesktop ? (
        <Col
          span={24}
          just
          style={{ backgroundColor: "black", gap: "5px", padding: "10px" }}
        >
          <Row
            style={{ justifyContent: "center", gap: "8px" }}
            className="tech-partner"
          >
            <Link
              href="https://ginkgoretail.com/ecommerce-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text style={{ color: "white", alignSelf: "center" }}>
                Technology Platform -
              </Text>
              <div style={{ alignSelf: "center", display: "flex" }}>
                <Image
                  src={comverse_ginkgo_logo}
                  width={181}
                  height={26}
                  alt="ginkgo retail"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Link>
          </Row>
        </Col>
      ) : (
        <Col
          span={24}
          just
          style={{ backgroundColor: "black", padding: "10px" }}
        >
          <Row
            style={{ justifyContent: "center", gap: "5px" }}
            className="tech-partner"
          >
            <Link
              href="https://ginkgoretail.com/ecommerce-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: "10px",
                  alignSelf: "center",
                  marginTop: "1px",
                  fontWeight: "bold",
                }}
              >
                Technology Platform -
              </Text>
              <div style={{ alignSelf: "center", display: "flex" }}>
                <Image
                  src={comverse_ginkgo_logo}
                  height={20}
                  style={{ objectFit: "contain" }}
                  alt="ginkgo retail"
                />
              </div>
            </Link>
          </Row>
        </Col>
      )}
    </div>
  );
}
