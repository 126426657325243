"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Row,
  Col,
  List,
  Radio,
  Dropdown,
  Typography,
  Input,
  Image,
} from "antd";
import { BsSearch } from "react-icons/bs";
import debounce from "lodash/debounce";
import Cookies from "js-cookie";
import Link from "next/link";
// import Image from "next/image";
import Flags from "country-flag-icons/react/3x2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
//? Assets --------------------------------------------------------------------->
import "./Navigation.scss";
import { CgMenuRightAlt } from "react-icons/cg";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import logo from "../../../../assets/images/logos/nav-logo.svg";
import { AiFillCloseCircle } from "react-icons/ai";
//? Modules -------------------------------------------------------------------->
import MegaMenu from "../MegaMenu/MegaMenu";
import Sale from "../MegaMenu/sections/Sale";
import SearchBar from "../../SearchBar/SearchBar";
import Search from "../../SearchBar/Search";
import Brands from "../MegaMenu/sections/Brands/Brands";
import SideNavDrawer from "./SideNavDrawer/SideNavDrawer";
import LoginPage from "@/components/SignUpPage/LoginPage";
import LatestCatalogue from "../MegaMenu/sections/LatestCatalogue/LatestCatalogue";
import CartDropp from "@/components/CartPage/Modules/CartDropdown/CartDropp";
import WishlistDrop from "@/components/CheckoutPage/Modules/WishListModule/Modules/WishlistDropdown/WishlistDrop";
import LoginDropdown from "./LoginDropdown/LoginDropdown";
//? Redux Reducers ------------------------------------------------------------->
import { updateCartCount } from "@/redux/Slices/cartSlice";
import AccountStatus from "@/functions/Cookies/Cookies";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import {
  setDefaultCountry,
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../../../redux/Slices/multiLocationSlice";
import usePath from "@/hooks/usePath/usePath";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

function Navigation(props) {
  // console.log("Navigation calling");
  const [indexArray, setindexArray] = useState([]);
  var timeoutOnHover;
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [navigationOpenCheck, setnavigationOpenCheck] = useState(true);
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");

  // Initialize defaultCurrency with the value from cookies or a default value
  // const initialDefaultCurrency = defaultCurrencyFromCookies;

  const { loggedIn } = AccountStatus();

  const [layoutType, setLayoutType] = useState(null);
  const [menuIndex, setMenuIndex] = useState(0);
  const [menuColor, setMenuColor] = useState("");
  const [showHoverBorder, setShowHoverBorder] = useState(false);
  const [hoverCheckNav, setHoverCheckNav] = useState(false);
  const [megaMenu, setMegaMenu] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [showCurrencyDrop, setShowCurrencyDrop] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showSideNav, setShowSideNav] = useState(false);
  const [countryData, setCountryData] = useState(null);
  const [ShowMegaSearch, setShowMegaSearch] = useState(false);

  const [defaultCurrency, setDefaultCurrency] = useState(
    defaultCurrencyFromCookies
  );
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [countryValue, setCountryValue] = useState(defaultCountryFromCookies);
  const [countryID, setCountryID] = useState(defaultCurrencyIdFromCookies);
  const [chnagedCurrencyName, setChangedCurrencyName] = useState(
    defaultCurrencyFromCookies
  );

  // â¬‡ Redux Reducers States
  const showMegaMenu = useSelector((state) => state?.megaMenu?.showMegaMenu);
  const showFullSearch = useSelector(
    (state) => state?.showComponentSlice?.showFullSearch
  );

  const defaultCountry = useSelector(
    (state) => state.multiLocation.defaultCountry
  );
  const defaultCountryId = useSelector(
    (state) => state.multiLocation.defaultCountryId
  );

  const cartCountTotalValue = useSelector(
    (state) => state.cartSlice.cartCountTotal
  );

  const [hideCurrencyDropdown, setHideCurrencyDropdown] = useState(false);
  const path = usePath();

  const [countryIdFromCookies, setCountryIdFromCookies] = useState(
    defaultCurrencyIdFromCookies
  );

  useEffect(() => {
    if (indexArray?.length === 0) {
      setShowHoverBorder(false);
    }
    if (hoverCheckNav) {
      setShowHoverBorder(false);
      // setindexArray([]);
    }
  }, [indexArray, hoverCheckNav]);

  const setValuesOfNavigationHover = (
    layout_type,
    indexValue,
    tabBackgroundColor,
    tabChildren
  ) => {
    setHoverCheckNav(false);
    setLayoutType(layout_type);
    setMenuIndex(indexValue);
    setMenuColor(tabBackgroundColor);
    setTimeout(() => {
      setShowHoverBorder(true); //hard code
      dispatch(setShowMegaMenu(true)); //hard code
    }, 100);
    setMegaMenu(tabChildren);
    let arrayOfIndex = [...indexArray];
    arrayOfIndex.push(indexValue);
    setindexArray([...arrayOfIndex]);
  };

  const callDebounceFunction = useCallback(
    debounce(setValuesOfNavigationHover, 300),
    []
  );

  // Define the API endpoint for geolocation
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const initializeData = async () => {
    const geoLocationUrl =
      "https://pro.ip-api.com/json/?fields=61439&key=FmwVTqAjxMiJk3z";
    const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;
    try {
      const apiData = await fetchData(geoLocationUrl);

      const countryListData = await fetchData(countryDataUrl);

      setCountryData(countryListData);
      const matchingCountry = countryListData?.country_list.find(
        (countryItem) => countryItem.country === apiData.country
      );

      if (matchingCountry) {
        setDefaultCurrency(defaultCurrencyFromCookies);
        setCountryValue(defaultCountryFromCookies);
        // setMyDefaultCountry(matchingCountry);
        dispatch(setDefaultCountry(matchingCountry));
        setCountryIdFromCookies(matchingCountry.id);
        dispatch(changeCountryId(matchingCountry.id));
        dispatch(changeCountry(matchingCountry.country));
        dispatch(changeCountryCode(matchingCountry.short_code));
        dispatch(changeCurrency(matchingCountry.currency));
      } else {
        console.log("No matching country found");
        setDefaultCurrency("USD");
        setCountryValue("United States");
        setCountryID("24");

        dispatch(setDefaultCountry("United States"));
        dispatch(changeCountryId("35"));
        dispatch(changeCountry("United States"));
        dispatch(changeCountryCode(""));
        dispatch(changeCurrency("USD"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!defaultCurrencyIdFromCookies || !defaultCurrencyFromCookies) {
      initializeData();
    }
  }, [defaultCurrency, defaultgeoLocationCountry, countryID]);

  const { storefrontListData } = props;

  useEffect(() => {
    const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
    const defaultCountryFromCookies = Cookies.get("defaultCountry");

    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, []);

  useEffect(() => {
    setDefaultCurrency(defaultCurrencyFromCookies);
    setCountryValue(defaultCountryFromCookies);
  }, [defaultCurrencyFromCookies, defaultCountryFromCookies]);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dispatch(setShowMegaMenu(false));
      }
    },
    [dispatch]
  );

  const handleCurrencyChange = (
    selectedCurrency,
    currencyId,
    short_code,
    currencyName,
    country
  ) => {
    // Update the state
    window.location.reload();

    setDefaultCurrency(selectedCurrency);

    Cookies.set("defaultCurrencyName", currencyName, { expires: 365 }); // Store currency name in cookies
    Cookies.set("defaultCurrencyId", currencyId, { expires: 365 });
    Cookies.set("defaultCountry", country, { expires: 365 }); // Store currency ID in cookies

    dispatch(changeCountryId(currencyId));
    dispatch(changeCountry(country));
    dispatch(changeCountryCode(short_code));
    dispatch(changeCurrency(currencyName));
  };

  useEffect(() => {
    // setHideCurrencyDropdown()
    path.includes("thankyou")
      ? setHideCurrencyDropdown(true)
      : setHideCurrencyDropdown(false);
  }, [path]);

  // thankyou

  const items = showCurrencyDrop
    ? [
        {
          key: "0",
          label: (
            <List
              size="small"
              dataSource={storefrontListData?.country_list}
              style={{ width: "220px", padding: "20px 5px 5px 5px" }}
              renderItem={(item, index) => (
                <List.Item
                  style={{
                    padding: "4px 0px",
                    fontSize: "12px",
                  }}
                >
                  <Radio.Group
                    className="currency-radioGroup"
                    value={defaultCurrency}
                    onChange={(e) => {
                      const selectedCurrency = e.target.value;
                      const selectedItem = storefrontListData.country_list.find(
                        (item) => item.currency === selectedCurrency
                      );
                      if (selectedItem) {
                        handleCurrencyChange(
                          selectedCurrency,
                          selectedItem.id,
                          selectedItem.short_code,
                          selectedItem.currency,
                          selectedItem.country
                        );
                      }
                    }}
                  >
                    {/* {storefrontListData.country_list.map((item) => ( */}
                    <Radio value={item.currency} key={item.id}>
                      <Row
                        className="radio-item"
                        align="middle"
                        justify={"space-between"}
                      >
                        <Col span={18} className="currency-name">
                          {item.currency}
                        </Col>
                        <Col span={6}>
                          {displayCountryFlag(item?.short_code)}
                        </Col>
                      </Row>
                    </Radio>
                    {/* ))} */}
                  </Radio.Group>
                </List.Item>
              )}
            />
          ),
        },
        {
          key: "1",
          label: (
            <div
              style={{
                textAlign: "center",
                padding: "10px 0px 0px 0px",
                borderTop: " 1px solid #f2f3f7",
              }}
            >
              <AiFillCloseCircle style={{ fontSize: "20px" }} />
            </div>
          ),
        },
      ]
    : [];
  useEffect(() => {
    const defaultCurrencyFromCookiess = Cookies.get("defaultCurrencyName");

    // window.location.reload();
    setDefaultCurrency(defaultCurrencyFromCookiess);
  }, []);
  useEffect(() => {
    // Add a listener for beforeunload event to clear the state on page reload/refresh
    const handleBeforeUnload = () => {
      dispatch(setShowMegaMenu(false));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, dropdownRef.current]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const handleMouseLeave = () => {
      dispatch(setShowMegaMenu(false));
    };

    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  });

  const displayCountryFlag = (flagCode) => {
    var Flag = null;
    Flag = Flags[flagCode];
    return Flag && <Flag style={{ width: "28px" }} />;
  };
  useEffect(() => {
    if (showMegaMenu) {
      document.body.classList.add("dropdown-opened");
    } else {
      document.body.classList.remove("dropdown-opened");
    }
  }, [showMegaMenu]);

  useEffect(() => {
    if (showMegaMenu) {
      dispatch(setShowMegaMenu(true));
    }
  }, [showMegaMenu]);
  let screenWidth;
  if (typeof window !== "undefined") {
    // Check if the code is running in a browser environment
    screenWidth = window.innerWidth;
  } else {
    screenWidth = 1024;
  }
  // const screenWidth = window?.innerWidth;

  const imageWidthHeightHandler = () => {
    switch (true) {
      case screenWidth >= 1024 && screenWidth < 1100:
        setImageWidth(100);
        setImageHeight(20);
        break;
      case screenWidth >= 1100 && screenWidth < 1199:
        setImageWidth(110);
        setImageHeight(22);
        break;

      default:
        setImageWidth(117);
        setImageHeight(24);
    }
  };

  useEffect(() => {
    if (showMegaMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showMegaMenu]);

  useEffect(() => {
    imageWidthHeightHandler();
  }, [imageWidth, imageHeight]);
  const getBrandsData = useCallback(() => {
    var brandsData = [];

    props?.data?.navigation_bar?.main_navigation?.forEach((navItem) => {
      if (
        navItem.label.toLowerCase() === "brands" ||
        navItem.label.toLowerCase() === "brand"
      ) {
        brandsData = [...brandsData, ...navItem.children];
      }
    });

    setBrandsData(brandsData);
  }, [props?.data?.navigation_bar?.main_navigation]);

  useEffect(() => {}, [loggedIn]);

  useEffect(() => {
    getBrandsData();
  }, [getBrandsData, props]);

  const handleVisibleChange = (visible) => {
    if (visible) {
      // Dropdown is being opened
      document.body.classList.add("no-scroll", "overlay-container");
      // Call your function or perform actions when the dropdown opens
    } else {
      // Dropdown is being closed
      document.body.classList.remove("no-scroll", "overlay-container");
      // Call your function or perform actions when the dropdown closes
    }
    setIsDropdownOpen(visible);
  };

  useEffect(() => {
    dispatch(setShowMegaMenu(false));
  }, []);

  return (
    <Col className="header-inner">
      <Row
        align="middle"
        justify="center"
        // style={{ padding: "0px 40px" }}
        className={
          showFullSearch
            ? "NavigationContainer flexCenter"
            : "NavigationContainer"
        }
      >
        {!ShowMegaSearch ? (
          <Col xl={4} lg={3} md={2}>
            {props?.data?.navigation_bar?.show_category_structure && (
              <Row
                align="middle"
                justify="start"
                gutter={[30, 0]}
                // ref={catRef}
                style={{ cursor: "pointer" }}
              >
                <Col
                  xl={6}
                  lg={6}
                  onClick={() => {
                    setShowSideNav(!showSideNav);
                    setLayoutType(null);
                  }}
                >
                  <div style={{ marginTop: "5px", paddingLeft: "10px" }}>
                    <CgMenuRightAlt color="black" className="font18" />
                  </div>
                </Col>
                <Col xl={18} lg={18}>
                  <Link href={"/"}>
                    <img
                      src={logo.src}
                      width={imageWidth}
                      height={imageHeight}
                      alt="Sanaulla"
                      preview={false}
                    />
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
        ) : null}

        {!ShowMegaSearch ? (
          <Col xl={10} lg={11} md={10} style={{ alignItems: "normal" }}>
            <Row
              className="header-navigation"
              align="normal"
              justify="start"
              gutter={[10, 0]}
              style={{ height: "100%" }}
            >
              <div className="alignCenter gap5">
                {props?.data?.navigation_bar?.main_navigation?.map(
                  (tab, index) => {
                    return (
                      <Link
                        key={index}
                        className="navLink"
                        style={{
                          borderBottom:
                            (layoutType === tab?.layout_type &&
                              menuIndex === index &&
                              showHoverBorder &&
                              !showMegaMenu &&
                              `4px solid ${tab?.background_color}`) ||
                            (layoutType === tab?.layout_type &&
                              menuIndex === index &&
                              showMegaMenu &&
                              `4px solid ${tab?.background_color}`),
                        }}
                        onMouseEnter={(e) => {
                          setShowHoverBorder(true); //hard code
                          setLayoutType(tab.layout_type);
                          setMenuIndex(index);
                          setMenuColor(tab?.background_color);
                          dispatch(setShowMegaMenu(true)); //hard code
                          setMegaMenu(tab?.children);
                          // callDebounceFunction(tab?.layout_type, index, tab?.background_color, tab?.children);
                        }}
                        onMouseOut={(e) => {
                          setHoverCheckNav(true);
                          setShowHoverBorder(false);
                          setindexArray([]);
                          // dispatch(setShowMegaMenu(false));
                        }}
                        href={tab?.link ? "/" + tab?.link : ""}
                      >
                        <Col
                          xl={
                            14 /
                            props?.data?.navigation_bar?.main_navigation?.length
                          }
                          lg={
                            14 /
                            props?.data?.navigation_bar?.main_navigation?.length
                          }
                          className="paddingTop15"
                        >
                          <Text style={{ color: tab?.text_color }}>
                            {tab?.label}
                          </Text>
                        </Col>
                      </Link>
                    );
                  }
                )}
              </div>
            </Row>
          </Col>
        ) : null}

        <Col
          xl={ShowMegaSearch ? 12 : 5}
          lg={ShowMegaSearch ? 12 : 5}
          className="searchBar-container"
        >
          {countryIdFromCookies !== undefined && countryIdFromCookies ? (
            <SearchBar
              userAgent={props?.userAgent}
              ShowMegaSearch={ShowMegaSearch}
              setShowMegaSearch={setShowMegaSearch}
              countryIdFromCookies={countryIdFromCookies}
            />
          ) : (
            <Col span={24} className="search-bar">
              <Input
                size="small"
                placeholder="Search Product, Brand or Code"
                className="searchInput borderNone"
                suffix={<BsSearch />}
              />
            </Col>
          )}
          {/* <Search userAgent={props?.userAgent} /> */}
        </Col>

        {!ShowMegaSearch ? (
          <Col xl={5} lg={5} className="icons">
            <Col>
              <WishlistDrop />
            </Col>
            {!hideCurrencyDropdown && (
              <Col
                onMouseOver={() => setSelectedIcon(2)}
                onMouseOut={() => setSelectedIcon(null)}
                className="currencyDropDown"
              >
                <Dropdown
                  menu={{ items }}
                  overlayClassName="currency-dropDown"
                  placement="bottomRight"
                  width={"200px"}
                  autoFocus={false}
                  trigger={["click"]}
                  onOpenChange={handleVisibleChange}
                  open={isDropdownOpen}
                  onMouseEnter={() => {
                    setShowCurrencyDrop(true);
                  }}
                >
                  <Text
                    className={
                      selectedIcon === 2
                        ? "icon-container icon-container-active"
                        : "icon-container"
                    }
                  >
                    <HiOutlineCurrencyDollar />
                    <span className="name">
                      {defaultCurrencyFromCookies ? (
                        defaultCurrencyFromCookies
                      ) : (
                        <div className="loading-dots">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </span>
                  </Text>
                </Dropdown>
              </Col>
            )}
            <Col>
              {/* TODO: add cart count here */}
              <div style={{ position: "relative" }}>
                {cartCountTotalValue > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      textAlign: "center",
                      top: "7px",
                      fontSize: "9px",
                      fontWeight: "bold",
                      color: "#ff0d50",
                      width: "100%",
                    }}
                  >
                    {cartCountTotalValue}
                  </div>
                )}
                <CartDropp />
              </div>
            </Col>

            <Col>
              <LoginDropdown />
            </Col>
          </Col>
        ) : null}
      </Row>

      <LoginPage
        ShowLoginModal={ShowLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
      <>
        {showMegaMenu && <div className="overlay"></div>}

        {showMegaMenu && (
          <Row
            ref={dropdownRef}
            align="middle"
            justify="center"
            style={{ position: "relative" }}
          >
            <Col
              xl={24}
              lg={24}
              style={{
                zIndex: 2,
                width: "86%",
                position: "fixed",
                top: "50px",
              }}
            >
              {layoutType === "layout_1" && (
                <Brands data={brandsData} menuColor={menuColor} />
              )}
              {layoutType === "layout_2" && (
                <MegaMenu data={megaMenu} menuColor={menuColor} />
              )}
              {layoutType === "layout_3" && (
                <Sale data={megaMenu} menuColor={menuColor} />
              )}
              {layoutType === "layout_4" && (
                <LatestCatalogue data={megaMenu} menuColor={menuColor} />
              )}
            </Col>
          </Row>
        )}
      </>

      {showSideNav && (
        <SideNavDrawer
          data={props?.data?.navigation_bar?.side_navigation}
          // data={null}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
          userAgent={props?.userAgent}
        />
      )}
    </Col>
  );
}

export default Navigation;
