import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import MegaSearchh from "../MegaSearch/MegaSearchh";

const Search = () => {
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const [ActivePage, setActivePage] = useState(1);
  const [SearchQuery, setSearchQuery] = useState("");
  const [SearchResults, setSearchResults] = useState([]);
  const [IsModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = async (value) => {
    
    setSearchQuery(value);
    try {
      const response = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
        `/storefront/search_products?page=${ActivePage}&q=${value}&limit=100&storefront=${defaultCurrencyIdFromCookies}`
      );
      const data = await response.json();
      setSearchResults(data.results);

      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Use debounce to delay the search query
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((q) => handleSearch(q), 1000),
    []
  );

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value); // Update the search query state immediately
    debouncedSearch(value); // Call debouncedSearch with the input value
  };

  return (
    <div>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={SearchQuery}
        onChange={handleInputChange} // Change the event handler
        InputProps={{
          endAdornment: (
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
          ),
          style: {
            maxHeight: "36px",
            maxWidth: "282px",
            backgroundColor: "#F1F1F1",
            borderRadius: "0px",
            border: "none",
          },
          inputProps: {
            style: {
              fontSize: "14px",
            },
          },
          placeholder: "Search ...",
        }}
        InputLabelProps={{
          shrink: false,
        }}
        label=""
      />
      <MegaSearchh
        IsModalOpen={IsModalOpen}
        setIsModalOpen={setIsModalOpen}
        SearchResults={SearchResults}
      />
    </div>
  );
};

export default Search;
