"use client";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Image, Typography, Card, Space } from "antd";
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import "./Sale.scss";
import Link from "next/link";

const { Title } = Typography;
const { Meta } = Card;

export default function Sale(props) {
  const dispatch = useDispatch();
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const screenWidth = window.innerWidth;

  // Define image width and height based on screen width
  // let imageWidth, imageHeight;
  const imageWidthHeightHandler = () => {
    if (screenWidth < 1024) {
      setImageWidth(85);
      setImageHeight(100);
    } else if (screenWidth >= 1024 && screenWidth < 1100) {
      setImageWidth(100);
      setImageHeight(125);
    } else if (screenWidth >= 1100 && screenWidth < 1150) {
      setImageWidth(115);
      setImageHeight(145);
    } else if (screenWidth >= 1150 && screenWidth < 1200) {
      setImageWidth(120);
      setImageHeight(150);
    } else if (screenWidth >= 1200 && screenWidth < 1250) {
      setImageWidth(125);
      setImageHeight(160);
    } else if (screenWidth >= 1250 && screenWidth < 1300) {
      setImageWidth(130);
      setImageHeight(165);
    } else if (screenWidth >= 1300 && screenWidth < 1350) {
      setImageWidth(140);
      setImageHeight(170);
    } else if (screenWidth >= 1350 && screenWidth < 1400) {
      setImageWidth(140);
      setImageHeight(170);
    } else if (screenWidth >= 1400 && screenWidth < 1450) {
      setImageWidth(140);
      setImageHeight(170);
    } else {
      setImageWidth(150);
      setImageHeight(175);
    }
  };
  useEffect(() => {
    imageWidthHeightHandler();
  }, [screenWidth]);

  return (
    <Row
      justify="center"
      className="sale-container"
      ref={props?.megaMenuRef}
      style={{
        background: "#F2F3F7",
        height: "auto",
        paddingBottom: 20,
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
      }}
      onClick={() => dispatch(setShowMegaMenu(false))}
      gutter={[10, 0]}
    >
      <Col span={11} className="sale-container-left">
        <Row
          style={{ flexDirection: "column" }}
          onClick={() => dispatch(setShowMegaMenu(false))}
        >
          <Col span={24}>
            <Title
              level={5}
              style={{
                color: props?.menuColor,
              }}
            >
              {props?.data[0]?.label}
            </Title>
            <Row>
              {props?.data[0]?.brand_list?.map((item, key) => (
                <Col
                  key={key}
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "4px solid #F2F3F7",
                    background: "#ffffff",
                    padding: "10px",
                    height: "80px",
                  }}
                  className="paddinng0"
                >
                  <Link href={"/"+item?.handle}>
                    <Image
                      alt={item?.name}
                      src={item?.image?.cdn_link}
                      height={"auto"}
                      width={"80px"}
                      preview={false}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>

          <Col span={24}>
            <Title
              level={5}
              style={{
                color: props?.menuColor,
              }}
            >
              {props?.data[1]?.label}
            </Title>
            <Space>
              {props?.data[1]?.collection_list?.map((item, key) => (
                <Col key={key}>
                  <Link href={"/"+item?.handle}>
                    <Card
                      hoverable
                      cover={
                        <Image
                          alt={item?.name}
                          src={item?.banner_image?.cdn_link}
                          height={"auto"}
                          preview={false}
                        />
                      }
                    >
                      <Meta title={item?.collection_text} />
                    </Card>
                  </Link>
                </Col>
              ))}
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={13} className="sale-container-right">
        <Title
          level={5}
          style={{
            color: props?.menuColor,
          }}
        >
          {props?.data[2]?.label}
        </Title>
        <Space className="flexWrap sale-container-right-grid">
          {props?.data[2]?.collection_list?.map((item, key) => (
            <Col
              key={key}
              span={6}
              className="right-collections-cards padding0"
            >
              <Link href={"/"+item?.handle}>
                <Image
                  alt={item?.name}
                  src={item?.banner_image?.cdn_link}
                  // height={imageHeight}
                  // width={imageWidth}
                  preview={false}
                />
              </Link>
            </Col>
          ))}
        </Space>
      </Col>
    </Row>
  );
}
