"use client";
//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import { Input, Col, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
// import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback, Suspense } from "react";
import { getCurrentDateTime } from "@/functions/HelperFunctions/index";

//? Assets --------------------------------------------------------------------->
import "./SearchBar.scss";
import { BsSearch } from "react-icons/bs";
//? Modules -------------------------------------------------------------------->
import MegaSearch from "../MegaSearch/MegaSearch";
import MegaSearchCustom from "../MegaSearch/MegaSearchCustom";
import Cookies from "js-cookie";
//? Redux Reducers ------------------------------------------------------------->
// import { setShowFullSearchBar } from "../../../redux/Slices/showComponentSlice";

//? Library Constants ---------------------------------------------------------->
const { Search } = Input;

const SearchBar = ({
  userAgent,
  ShowMegaSearch,
  setShowMegaSearch,
  countryIdFromCookies,
}) => {
  //? Hooks ---------------------------------------------------------------------->
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = userAgent;

  //? Redux States --------------------------------------------------------------->
  //? States --------------------------------------------------------------------->
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const [mainLoading, setMainLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ActivePage, setActivePage] = useState(1);
  const [SearchQuery, setSearchQuery] = useState("");
  const [SearchResults, setSearchResults] = useState({});
  const [SearchedProducts, setSearchedProducts] = useState([]);
  const [categorySearchFlag, setcategorySearchFlag] = useState(false);
  const [onlyChangeProductsFlag, setOnlyChangeProductsFlag] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [loadMoreButtonFlag, setLoadMoreButtonFlag] = useState(false);
  //? Functions ------------------------------------------------------------------>
  const handleSearch = (e) => {
    const value = e.target.value;
    setLoading(true);
    setActivePage(1);
    setSearchQuery(value);
    searchResult(value, true);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("q", value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };
  const handleSearchOnClick = (searchString) => {
    setLoading(true);
    setActivePage(1);
    setSearchQuery(searchString);
    searchResult(searchString, true);
  };

  const moengageEventForSearch = (searchedString) => {
    let currentData = getCurrentDateTime();
    if (window.Moengage && window.Moengage.track_event) {
      window.Moengage.track_event("Product Searched", {
        "Event Received Time": currentData,
        Currency: defaultCurrencyFromCookies
          ? defaultCurrencyFromCookies
          : "PKR",
        "Search String": searchedString,
        Source: "Website",
        // "First Session": true
      });
    }
  };

  const searchResult = (query, megaMenuFlag) => {
    if (!onlyChangeProductsFlag) {
      moengageEventForSearch(query);
      setMainLoading(true);
    } else {
      setProductLoader(true);
    }
    setcategorySearchFlag(false);

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${ActivePage}&q=` +
          query +
          "&limit=18" +
          `&storefront=${countryIdFromCookies}`
      )
      .then((response) => {
        if (!onlyChangeProductsFlag) {
          setSearchResults(response?.data);
        }
        setSearchedProducts(response?.data?.results);
        // if (megaMenuFlag) {
        //   setShowMegaSearch(true);
        // }
        if (response?.data?.results?.length > 17) {
          setLoadMoreButtonFlag(true);
        } else {
          setLoadMoreButtonFlag(false);
        }
        setMainLoading(false);
        setOnlyChangeProductsFlag(false);
        setProductLoader(false);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
        setTimeout(() => {
          setMainLoading(false);
        }, 1000);
        setOnlyChangeProductsFlag(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuery = useCallback(debounce(handleSearch, 1000), []);

  useEffect(() => {
    if (ShowMegaSearch) {
      let initialString = "";
      searchResult(initialString, false);
    }
  }, []);

  useEffect(() => {
    if (categorySearchFlag && ShowMegaSearch) {
      searchResult(SearchQuery, true);
    }
  }, [SearchQuery]);

  return (
    <Col span={24} className="search-bar">
      <Input
        size="small"
        placeholder="Search Product, Brand or Code"
        className="searchInput borderNone"
        suffix={<BsSearch />}
        onChange={searchQuery}
        onPressEnter={searchQuery}
        onClick={(e) => {
          setShowMegaSearch(true);
          if (!ShowMegaSearch) {
            handleSearchOnClick(e.target.value);
          }
        }}
      />
      <MegaSearchCustom
        userAgent={userAgent}
        Loading={Loading}
        setLoading={setLoading}
        ActivePage={ActivePage}
        SearchQuery={SearchQuery}
        setSearchQuery={setSearchQuery}
        SearchResults={SearchResults}
        setActivePage={setActivePage}
        ShowMegaSearch={ShowMegaSearch}
        setShowMegaSearch={setShowMegaSearch}
        SearchedProducts={SearchedProducts}
        setSearchedProducts={setSearchedProducts}
        setcategorySearchFlag={setcategorySearchFlag}
        setOnlyChangeProductsFlag={setOnlyChangeProductsFlag}
        mainLoading={mainLoading}
        productLoader={productLoader}
        setProductLoader={setProductLoader}
        setLoadMoreButtonFlag={setLoadMoreButtonFlag}
        loadMoreButtonFlag={loadMoreButtonFlag}
      />
    </Col>
  );
};

export default SearchBar;
