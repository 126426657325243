//? Library Imports ------------------------------------------------------------>
import { useRouter } from "next/navigation";
import { signOut } from "next-auth/react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { Typography, Row, Drawer, Space, Col, message } from "antd";

//? Assets --------------------------------------------------------------------->
import "./AccountsSideNavMobile.scss";
import { AiOutlineMenu } from "react-icons/ai";

//? Modules -------------------------------------------------------------------->
import DynamicButton from "@/components/shared/Buttons/DynamicButton/DynamicButton";

//? Redux Reducers ------------------------------------------------------------->
import {
  logoutReducer,
  changeActiveMenuReducer,
} from "@/redux/Slices/accountSlice";
import AccountStatus, { customerLogout } from "@/functions/Cookies/Cookies";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function AccountsSideNavMobile() {
  const router = useRouter();
  const dispatch = useDispatch();

  const {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();

  //? Redux States --------------------------------------------------------------->
  const comverse_customer_name = useSelector(
    (state) => state.accountSlice?.comverse_customer_name
  );
  const ActiveMenu = useSelector((state) => state.accountSlice?.activeMenu);

  const [open, setOpen] = useState(false);
  // const [ActiveMenu, setActiveMenu] = useState("dashboard");

  //? Functions ------------------------------------------------------------------>
  const logOut = useCallback(() => {
    message.success("Logged out successfully!");
    signOut({ redirect: false });
    onClose();
    customerLogout();
    dispatch(logoutReducer());
    router.push("/");
  }, [dispatch, router]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Col>
      <Space>
        <AiOutlineMenu onClick={showDrawer} style={{ fontSize: "18px" }} />
      </Space>
      <Drawer
        open={open}
        width={238}
        placement="left"
        onClose={onClose}
        className="accountsSidebar"
        title={
          <Col className="">
            <Col style={{ textAlign: "end", color: "#5A7184" }}>
              <Text
                className="font11"
                style={{ color: "#5A7184" }}
                onClick={() => {
                  dispatch(changeActiveMenuReducer("edit"));
                  onClose();
                }}
              >
                Edit
              </Text>
            </Col>

            <Space direction="vertical" className="gap0">
              <Text className="margin0 font18Bold">Account</Text>
              <Text className="margin0 font18">
                {comverse_customer_name ? comverse_customer_name : "N/A"}
              </Text>
            </Space>
          </Col>
        }
      >
        <Col className="sideBar">
          <Col span={24} className="padding0">
            <Col>
              <Row className="width100">
                <Col
                  span={24}
                  // onClick={() =>  setActiveMenu("dashboard")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("dashboard"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "dashboard"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Dashboard</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("orders")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("orders"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "orders"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>My Order</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("wishlist")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("wishlist"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "wishlist"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Wishlist</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("address")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("address"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "address"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Address Book</Text>
                </Col>
                {/* <Col
                  span={24}
                  // onClick={() => setActiveMenu("points")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("points"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "points"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Reward Point</Text>
                </Col> */}
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("wallet")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("wallet"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "wallet"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>My Wallet</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("faq")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("faq"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "faq"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>FAQs</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("about")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("about"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "about"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>About Us</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("terms")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("terms"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "terms"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Terms & Conditions</Text>
                </Col>
                <Col
                  span={24}
                  // onClick={() => setActiveMenu("privacy")}
                  onClick={() => {
                    dispatch(changeActiveMenuReducer("privacy"));
                    onClose();
                  }}
                  className={
                    ActiveMenu === "privacy"
                      ? "selectedMenuItem cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                      : "cursorPointer borderTop paddingTop10 paddingBottom10 paddingLeft40"
                  }
                >
                  <Text>Privacy Policy</Text>
                </Col>
              </Row>
            </Col>
          </Col>

          <Col span={24} className="marginTop40 paddingLeft25">
            <DynamicButton
              name="Logout"
              function={logOut}
              style={{
                border: "none",
                color: "#BC1B41",
                background: "none",
                justifyContent: "start",
              }}
            />
          </Col>
        </Col>
      </Drawer>
    </Col>
  );
}
