"use client";

// Library Imports
import Parser from "html-react-parser";
import {
  Col,
  Row,
  // Typography
} from "antd";
import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import { useEffect, useState } from "react";

// Library Constnts

// const { Text, Title } = Typography;

export default function AddedFooter({ footer, userAgent }) {
  // const { isTablet, isMobile } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;
  const [showSeoDescription, setShowSeoDescription] = useState(true)



  // const addFooterData = [
  //   {
  //     title: "Clothing Brands",
  //     desciption:
  //       "Gul Ahmed | Al Karam | Asim Jofa | Neelos | Haute Form | Hussain Rehar | Crimson | Zara Shahjahan | Brumano | Furor | Edenrobe",
  //   },
  //   {
  //     title: "Men's Clothing",
  //     desciption:
  //       "Men's Polo Shirts | Men's Shirts | Men's Kameez Shalwar | Men's Waistcoats | Casual Blazers | Designer Kurta | Formal Pants",
  //   },
  //   {
  //     title: "Women's Clothing",
  //     desciption:
  //       "Summer Lawn Suits | Ladies Khussay | Chiffon Suits | Bridal Wear | Ladies Shawls | Abayas | Women's Nightwear | Wedding Dresses | Ladies Sweaters & Ponchos | Pret Wear | Luxury Pret",
  //   },
  //   {
  //     title: "Kids' Clothing",
  //     desciption:
  //       "Boys' Kameez Shalwar | Boys' Waistcoats | Girls Suits & Kurtis | Girls T-shirts | Girls Scarves | Kid's Nightwear",
  //   },
  //   {
  //     title:
  //       "Buy Pakistani Ladies Dresses Online through Best Online Clothing Store - Sanaulla Store",
  //     desciption:
  //       "- Sanaulla Store is the leading ladies’ fashion store in Pakistan, where you can find the latest Pakistani ladies suits by the top-notch brands of the country. Pioneering in the ladies eCommerce fashion industry Sanaulla Store has also been awarded the 3rd best online shopping store in the fashion and apparel sector of Pakistan. - Sanaulla Store has more than 400 brands, offering designer ladies’ clothing, that is authentic and 100% original. Sourced from the brands directly. Sanaulla is quite diligent when it comes to quality assurance and customer satisfaction. The reason behind, being a reputed and well-trusted brand, having an audience of more than 1 million people located in the UK, USA, Canada, Middle East, and more.",
  //   },
  //   {
  //     title: "How can you find Pakistani Ladies Dresses at Sanaulla Store?",
  //     desciption:
  //       "The brand has put in loads of effort crafting its eCommerce platform. Sanaullastore.com Having a comprehensive structure of the website, Sanaulla’s has around every category of ladies’ suits available online. Be it 3 piece designer or a ready-to-wear Pret category. Furthermore, you can find your desired dresses by being more specific, as the unstitched category has more sub-categories based on the fabric, formal & casuals, 2 pieces & 3 pieces. You can find luxury chiffon suits, lawn suits, organza, Khaddar suits, and more along with luxury formals, , and categories. The same follows for the pret category also. Offering luxury pret, casual pret, ready-to-wear ladies suits. Sanaulla Online Store also gives you the option to search and find what you are looking for, using the search box placed at the top center. Plus, if you are looking for a dress in a particular price range then you can apply the price filter and set your min and max range to shop from.",
  //   },
  // ];

  return (
    <Row
      className={
        !isDesktop
          ? "added-footer mobile-added-footer footer-seo-description"
          : "added-footer"
      }
    >
      <Col span={24}>
        {/* {footer?.seo_section &&
            footer?.seo_section?.map((item, index) => (
              <Col lg={index < 4 ? 10 : 24} key={index} className="section">
                <Title level={index < 4 ? 5 : 3}>{item.heading}</Title>

                <Text className="footer-font-12">{item.description}</Text>
              </Col>
            ))} */}

        <Row className="sections" justify={"space-between"}>
          {footer?.left_col_seo ? (
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              {Parser(footer?.left_col_seo)}
            </Col>
          ) : null}
          {footer?.right_col_seo ? (
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              {Parser(footer?.right_col_seo)}
            </Col>
          ) : null}
          {footer?.lower_section_seo && (
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="footer-seo-description"
            >
              {Parser(footer?.lower_section_seo)}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
